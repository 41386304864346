import React from "react";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridExportCsvOptions,
} from "@material-ui/x-grid";

import GridToolbarQuickFilterField from "./GridToolbarQuickFilterField";

export type Feature =
  | "FilterButton"
  | "ColumnsButton"
  | "DensitySelector"
  | "Export"
  | "QuickFilter";

export type Features = {
  [key in Feature]?: boolean;
};

const csvOptions: GridExportCsvOptions = {
  allColumns: true,
};

export interface Options {
  fileName?: string;
}

export interface CreateToolBarArg {
  features?: Features;
  options?: Options;
}

export const DefaultFeatures: Features = {
  FilterButton: true,
  ColumnsButton: true,
  DensitySelector: true,
  Export: true,
  QuickFilter: true,
};

export const createTableToolbar = ({
  features = DefaultFeatures,
  options = {},
}: CreateToolBarArg = {}) => {
  const Component = function TableToolbar({
    quickFilter,
    onQuickFilterChange,
  }) {
    return (
      <GridToolbarContainer>
        {features["FilterButton"] ? <GridToolbarFilterButton /> : null}
        {features["ColumnsButton"] ? <GridToolbarColumnsButton /> : null}
        {features["DensitySelector"] ? <GridToolbarDensitySelector /> : null}
        {features["Export"] ? (
          <GridToolbarExport
            csvOptions={
              options?.fileName
                ? {
                    ...csvOptions,
                    fileName: options.fileName,
                  }
                : csvOptions
            }
          />
        ) : null}
        {features["QuickFilter"] ? (
          <>
            <div style={{ flex: 1 }} />
            <GridToolbarQuickFilterField
              quickFilter={quickFilter}
              onQuickFilterChange={onQuickFilterChange}
            />
          </>
        ) : null}
      </GridToolbarContainer>
    );
  };

  Component.displayName = `TableToolbar(${Object.entries(features)
    .filter(([k, v]) => v)
    .map(([k, v]) => k)
    .join(", ")})`;

  return Component;
};

const TableToolbar = createTableToolbar();

export default TableToolbar;
