import React, { type Dispatch, type SetStateAction } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import type { AmazonIntegration } from "src/interfaces/plaidIntegration.interface";
import { type DataType } from "src/pages/GetStarted/ImportDialog";
import { isAmazonAllowedToDirectImportSelector } from "src/store/uploads/selector";
import { amazonIntegrationDelete } from "src/apiService/modules/amazon";
import {
  amazonSyncApi,
  amazonDirectImportInventory,
  amazonDirectImportSales,
  amazonIntegrationSyncChange,
} from "src/apiService/modules/amazon";

import type { DialogState } from "../types";
import PlatformIntegrationsTableRow from "../PlatformIntegrationsTableRow";
import useIsRunning from "../useIsRunning";

// fix-vim-highlight = }

const IsRunningThreshold = 10 * 60 * 1000; // 10 min
const DirectImportThreshold = 2 * 60 * 60 * 1000;

export function usePreventImportAmazon(dataType: DataType) {
  return !useSelector((state: any) =>
    isAmazonAllowedToDirectImportSelector(state, dataType)
  );
}

function AmazonIntegrationTableRow({
  integration,
  setDialog,
  hideInventory,
}: {
  integration: AmazonIntegration;
  setDialog: Dispatch<SetStateAction<DialogState>>;
  hideInventory: boolean;
}) {
  const isRunning = useIsRunning(
    Math.max(
      integration.salesIsRunning || 0,
      (integration.reports?.directImportSales?.createdAt?.toDate()?.valueOf() ||
        0) + DirectImportThreshold,
      (integration.reports?.dailySyncSales?.createdAt?.toDate()?.valueOf() ||
        0) + DirectImportThreshold
    ),
    Math.max(
      integration.inventoryIsRunning || 0,
      (integration.reports?.directImportInventory?.createdAt
        ?.toDate()
        ?.valueOf() || 0) + DirectImportThreshold,
      (integration.reports?.dailySyncInventory?.createdAt
        ?.toDate()
        ?.valueOf() || 0) + DirectImportThreshold
    ),
    IsRunningThreshold
  );

  return (
    <PlatformIntegrationsTableRow
      key={integration.id}
      name={integration.username || "Amazon"}
      username={integration.username || integration.sellingPartnerId}
      platform="Amazon"
      isRunning={isRunning}
      sync={integration.sync}
      inventory={integration.sync && integration.inventory !== false}
      onPullDataNow={async () => {
        await amazonSyncApi(integration.id);
        toast.success("Request received. Your imports are processing.");
      }}
      onImport={() => {
        setDialog({
          type: "import",
          props: {
            integrationId: integration.id,
            fetchListings: amazonDirectImportInventory,
            fetchTransactions: amazonDirectImportSales,
            providerName: "Amazon",
            usePreventImport: usePreventImportAmazon,
          },
        });
      }}
      onSyncChanged={async (sync, inventory) => {
        await amazonIntegrationSyncChange(integration.id, sync, inventory);
        if ((inventory === undefined || inventory) && sync)
          amazonSyncApi(integration.id);
      }}
      hideInventory={hideInventory}
      onDelete={() =>
        setDialog({
          type: "delete",
          props: {
            integration,
            deleteIntegration: amazonIntegrationDelete,
          },
        })
      }
    />
  );
}

export default AmazonIntegrationTableRow;
