import React, { useState, useEffect } from "react";
import { produce } from "immer";
import { useDispatch } from "react-redux";
import lodashGet from "lodash/get";

import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { makeStyles } from "@material-ui/core/styles";

import { requestNOUpdate } from "src/apiService";
import { updatePendingUpload } from "src/store/sale/actions";
import { formatDollars } from "src/utils";
import NumberField from "src/components/NumberField";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: "50vh",
    overflowY: "auto",
  },
  toogle: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const Options = {
  title: {
    name: "Item Title",
    columns: [
      { key: "inventory.item_title", title: "Item Title" },
      { key: "inventory.sku", title: "Inventory SKU" },
      { key: "sale.sku", title: "Sale SKU" },
    ],
  },
  sku: {
    name: "SKU",
    columns: [
      { key: "inventory.sku", title: "SKU" },
      { key: "inventory.item_title", title: "Inventory Item Title" },
      { key: "sale.item_title", title: "Sale Item Title" },
    ],
  },
};

const SalesMatchingTable = ({
  gotoManualMatch,
  // inventoryMatches,
  // automaticMatches,
  noAutomaticMatchCount,
  selectedIndices,
  toggleSelectAll,
  toggleSelectedIndex,
  render,
  inventoryMatchesByItemTitle,
  inventoryMatchesByItemSKU,
}: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [matchedBy, setMatchedBy] = useState<keyof typeof Options>("title");
  const option = Options[matchedBy];
  const _items =
    matchedBy === "title"
      ? inventoryMatchesByItemTitle
      : inventoryMatchesByItemSKU;
  const [items, setItems] = useState<typeof _items>([]);
  useEffect(() => {
    setItems((items) =>
      _items.map((i) => {
        let purchase_price =
          Math.round(
            ((i.inventory.purchase_price || 0) * 100) /
              (i.inventory.quantity || 1)
          ) / 100;

        const prev = items.find(
          (pair) =>
            pair.sale.id === i.sale.id && pair.inventory.id === i.inventory.id
        );
        if (prev?.inventory && "purchase_price" in prev.inventory)
          purchase_price = prev.inventory.purchase_price;

        return {
          ...i,
          inventory: {
            ...i.inventory,
            purchase_price_total: i.inventory.purchase_price,
            purchase_price,
          },
        };
      })
    );
  }, [_items]);

  const saveMatches = () => {
    const actions = Array.from(selectedIndices as Set<number>).map(
      (selectedIndex: number) => {
        const item = items[selectedIndex];
        const { inventory, sale } = item;
        if (inventory.item_title !== sale.item_title) {
          console.log({ mismatch: { inventory, sale, selectedIndex } });
        }
        const update = {
          ...sale,
          ...inventory,
          inventoryId: inventory.id,
          unmatched: false,
        };

        for (const key of ["import_date", "import_type", "import_platform"]) {
          delete update[key];
          if (key in inventory) update[`inventory_${key}`] = inventory[key];
        }

        return updatePendingUpload(update);
      }
    );
    actions.forEach(dispatch);
    toggleSelectAll(true, items);
    requestNOUpdate();
  };

  return render({
    title: (
      <>
        {`Matched by ${option.name}`}
        <ToggleButtonGroup
          className={classes.toogle}
          value={matchedBy}
          exclusive
          onChange={(e, value) => {
            if (Options[value]) setMatchedBy(value);
          }}
        >
          <ToggleButton value="title">Item Title</ToggleButton>
          <ToggleButton value="sku">SKU</ToggleButton>
        </ToggleButtonGroup>
      </>
    ),
    main: (
      <>
        <Box className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={selectedIndices.size === items.length}
                    disabled={!items.length}
                    onClick={() => toggleSelectAll(false, items)}
                  />
                </TableCell>
                {option.columns.map((col) => (
                  <TableCell key={col.key}>{col.title}</TableCell>
                ))}
                <TableCell>Current Inventory Quantity</TableCell>
                <TableCell>Current Total Inventory Value</TableCell>
                <TableCell>Purchase Price</TableCell>
                <TableCell>Sale Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => {
                const { sale, inventory } = item;
                return (
                  <TableRow key={`${sale.uuid}-${inventory.id}`}>
                    <TableCell>
                      <Checkbox
                        checked={selectedIndices.has(index)}
                        onClick={() => toggleSelectedIndex(index)}
                        disabled={!inventory}
                      />
                    </TableCell>
                    {option.columns.map((col) => (
                      <TableCell key={col.key}>
                        {lodashGet(item, col.key)}
                      </TableCell>
                    ))}
                    <TableCell>{inventory.quantity || 1}</TableCell>
                    <TableCell>
                      {formatDollars(inventory.purchase_price_total)}
                    </TableCell>
                    <TableCell>
                      <FormControl size="small" error={!!inventory.error}>
                        <NumberField
                          Component={Input as any}
                          value={inventory.purchase_price}
                          onChange={(e) => {
                            const value = e.target.value;
                            setItems((items) => {
                              const total = items.reduce((t, it, i) => {
                                if (i === index) return t + value;
                                if (it.inventory.id === inventory.id)
                                  return t + it.inventory.purchase_price;
                                return t;
                              }, 0);

                              return produce(items, (draft) => {
                                draft[index].inventory.purchase_price = value;
                                if (
                                  (items[index].inventory.quantity || 1) > 1 &&
                                  total >
                                    items[index].inventory.purchase_price_total
                                )
                                  draft[index].inventory.error =
                                    "Cannot allocate more than the current inventory total purchase price.";
                                else delete draft[index].inventory.error;
                              });
                            });
                          }}
                          {...({
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          } as any)}
                        />
                        {inventory.error ? (
                          <FormHelperText>{inventory.error}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </TableCell>
                    <TableCell>{formatDollars(sale.sale_price)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
        {!!noAutomaticMatchCount && (
          <Typography className="mt-4">
            {noAutomaticMatchCount} sales could not be matched automatically.
          </Typography>
        )}
      </>
    ),
    actions: (
      <>
        <Button color="secondary" variant="contained" onClick={gotoManualMatch}>
          Go to Manual Sales Matching
        </Button>
        <Button
          disabled={
            !selectedIndices.size || items.some((i) => !!i.inventory.error)
          }
          color="primary"
          variant="contained"
          onClick={saveMatches}
        >
          Add Selected Sales
        </Button>
      </>
    ),
  });
};

export default SalesMatchingTable;
