import type { ComponentType } from "react";

import LockOpenIcon from "@material-ui/icons/LockOpen";
import PersonIcon from "@material-ui/icons/Person";
import LocalMallIcon from "@material-ui/icons/LocalMall";
const base = "/support";

export interface NavigatorEntry {
  id: string;
  title: string;
  Icon: ComponentType;
  href: string;
  forPremium?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  order: number;
}

export const navigation: Array<NavigatorEntry> = [
  {
    id: "ebay-limits",
    title: "eBay Limits",
    Icon: LocalMallIcon,
    href: `${base}/ebay-limits`,
    order: 3,
  },
  {
    id: "user",
    title: "User",
    Icon: PersonIcon,
    href: `${base}/user/`,
    order: 2,
  },
  {
    id: "token",
    title: "Tokens",
    Icon: LockOpenIcon,
    href: `${base}`,
    order: 1,
  },
];
