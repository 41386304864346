import firebase from "firebase/app";
import "firebase/functions";
import firestore from "src/apiService/firestore";
import storage from "src/apiService/storage";
import { getUserId } from "src/config/storage";
import MonthYear from "src/interfaces/monthYear.interface";

export const fetchEbayListingsApi = (ebayAuth) => {
  const getInventoriesFunction = firebase
    .functions()
    .httpsCallable("geteBayInventory", { timeout: 300000 });
  return getInventoriesFunction({ accessToken: ebayAuth.access_token })
    .then((result) => {
      console.log({ data: result.data });
      return result.data;
    })
    .catch((err) => {
      console.log({
        code: err.code,
        message: err.message,
        details: err.details,
      });
    });
};

export async function fetchNewEbayListingsApi(integrationId) {
  const getInventoriesFunction = firebase
    .functions()
    .httpsCallable("getNeweBayInventory");

  const result = await getInventoriesFunction({ integrationId });
  return result.data;
}

export const fetchEbayTransactionsApi = (integrationId, year, month) => {
  const getTransactionsFunction = firebase
    .functions()
    .httpsCallable("geteBayTransactions", { timeout: 300000 });
  return getTransactionsFunction({
    integrationId,
    year,
    month,
  })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      console.log({
        code: err.code,
        message: err.message,
        details: err.details,
      });
    });
};

export async function fetchNewEbayTransactionsApi(
  integrationId: string,
  start: MonthYear,
  end: MonthYear
) {
  const getTransactionsFunction = firebase
    .functions()
    .httpsCallable("getNeweBayTransactions");

  const result = await getTransactionsFunction({
    integrationId,
    year: start.year,
    month: start.month,
    end,
    timezoneOffset: new Date().getTimezoneOffset(),
  });
  return result.data;
}

export async function ebaySyncApi(integrationId: string) {
  const f = firebase.functions().httpsCallable("ebaySync");
  const result = await f({ integrationId });
  return result.data;
}

export async function eBayUpdateItemTitles(file) {
  const userId = await getUserId();
  const storageRef = storage().ref();
  const filename: string = `ebayItemTitles/${userId}/${Date.now()}`;
  const uploadRef = storageRef.child(filename);
  const snapshot = await uploadRef.put(file);

  if (snapshot.state !== firebase.storage.TaskState.SUCCESS)
    throw new Error(
      "There was an error uploading the file. Please try again later"
    );

  const f = firebase.functions().httpsCallable("eBayUpdateItemTitles");
  await f({ filename });
}

export async function eBayIntegrationDelete(id: string) {
  const userId = await getUserId();
  const db = firestore();

  if (id === "ebay") {
    await db.collection("Users").doc(userId).update({
      ebayRefreshToken: firebase.firestore.FieldValue.delete(),
      ebayRefreshExpiration: firebase.firestore.FieldValue.delete(),
      ebayIntegration: firebase.firestore.FieldValue.delete(),
      ebayError: firebase.firestore.FieldValue.delete(),
      eBay_initialImport_sales: firebase.firestore.FieldValue.delete(),
      eBay_initialImport_inventory: firebase.firestore.FieldValue.delete(),
      ebayLastSyncDateTransactions: firebase.firestore.FieldValue.delete(),
      ebayLastSyncDateInventory: firebase.firestore.FieldValue.delete(),
    });
    return;
  }

  await db.runTransaction(async (transaction) => {
    const ref = db.collection("Plaid_Integrations").doc(id);
    const doc = await transaction.get(ref);
    if (!doc.exists) return;
    if (doc.data()?.user !== userId) return;
    if (doc.data()?.type !== "ebay") return;
    await transaction.delete(ref);
  });
}

export async function eBayIntegrationSyncChange(
  id: string,
  sync: boolean,
  inventory: boolean = true
) {
  const userId = await getUserId();
  const db = firestore();

  if (id === "ebay") {
    await db
      .collection("Users")
      .doc(userId)
      .update({
        ebayIntegration: sync,
        ebayIntegrationInventory: sync && inventory,
      });
    return;
  }

  await db.runTransaction(async (transaction) => {
    const ref = db.collection("Plaid_Integrations").doc(id);
    const doc = await transaction.get(ref);
    if (!doc.exists) return;
    if (doc.data()?.user !== userId) return;
    await transaction.update(ref, { sync, inventory: sync && inventory });
  });
}

export async function getEBayOAuthUrl(
  arg: (
    | { integrationId: string }
    | { integrationName: string }
    | { integrationId: "ebay"; integrationName: string }
  ) & { back?: string }
) {
  const f = firebase.functions().httpsCallable("getEBayOAuthUrl");

  return await f(arg);
}

export async function exchangeEBayOAuthCode({
  code,
  integrationId,
  integrationName,
}: {
  code: string;
  integrationId?: string;
  integrationName?: string;
}) {
  const f = firebase.functions().httpsCallable("exchangeEBayOAuthCode");

  return await f({ code, integrationId, integrationName });
}
