import React, { useRef, useState } from "react";
import { toast } from "react-toastify";

import Dialog, { type DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle, {
  type DialogTitleProps,
} from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { usePlaidLink } from "react-plaid-link";

import LoadableButton from "src/components/LoadableButton";
import {
  usePlaid,
  transferPlaidPublicToken,
  type PlaidIntegrationType,
} from "src/apiService/modules/plaid";

export interface PlaidAddIntegrationDialogContentProps {
  DialogTitle: React.ComponentType<DialogTitleProps>;
  onFinish: (name: string) => void;
  onGoBack: () => void;
  type: PlaidIntegrationType;
  redirect?: string;
}

const Now = new Date();
const MinDate = (() => {
  const d = new Date();
  d.setFullYear(d.getFullYear() - 2);
  return d;
})();

interface Values {
  name?: string;
  date?: Date | null;
}

type Errors = Record<string, string>;

function isValid(values: Values): Errors {
  const errors: Errors = {};

  if (!values.name || !values.name.trim()) {
    errors.name = "Required value";
  }

  if (!values.date || !values.date.getTime || isNaN(values.date.getTime())) {
    errors.name = "Required value";
  }

  return errors;
}

export function PlaidAddIntegrationDialogContent({
  onFinish,
  onGoBack,
  DialogTitle,
  type,
  redirect,
}: PlaidAddIntegrationDialogContentProps) {
  const [bag, setBag] = useState<{
    values: Values;
    errors: Errors;
    loading: boolean;
  }>({
    errors: {},
    values: {
      name: "",
      date: null,
    },
    loading: false,
  });
  const bagRef = useRef({
    bag,
    setBag,
  });
  bagRef.current.bag = bag;
  bagRef.current.setBag = setBag;

  const { linkToken } = usePlaid({
    type,
    name: bag.values.name,
    date: bag.values.date || undefined,
    redirect,
  });
  const { open: openPlaid } = usePlaidLink({
    onSuccess: async (publicToken) => {
      if (!bagRef.current.bag.values.name) {
        toast.success("You must provide a name");
        return;
      }
      await transferPlaidPublicToken(publicToken, type, {
        name: bagRef.current.bag.values.name,
        date: bagRef.current.bag.values.date || undefined,
      });
      if (bagRef.current.bag.values.name)
        onFinish(bagRef.current.bag.values.name);
    },
    onExit: (err) => {
      bagRef.current.setBag((b) => ({ ...b, loading: false }));
      console.log(err);
      if (err) {
        console.log(`Plaid encounters an error: ${err}`);
      }
    },
    token: linkToken || null,
  });

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        const errors = isValid(bag.values);
        if (Object.keys(errors).length) {
          setBag((b) => ({ ...b, errors }));
          return;
        }

        setBag((b) => ({ ...b, loading: true }));
        openPlaid();
      }}
    >
      <DialogTitle>Add New Credit Card/Bank Account</DialogTitle>
      <MuiDialogContent>
        <DialogContentText>
          You can connect a credit card or bank account to My Reseller Genie to
          pull your transaction data. Add a name for the credit card/bank
          account below, set a start date (we’ll pull as far back as the bank
          lets us), and click “Connect” to sign into your account.
        </DialogContentText>
        <Box display="flex">
          <Box flex={2} mr={2}>
            <TextField
              label="Account Name"
              variant="outlined"
              error={!!bag.errors.name}
              helperText={bag.errors.name}
              value={bag.values.name}
              onChange={(e) => {
                const name = e.target.value;
                setBag((b) => ({ ...b, values: { ...b.values, name } }));
              }}
              disabled={bag.loading}
              required
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                variant="inline"
                inputVariant="outlined"
                disableToolbar
                format="MM/dd/yyyy"
                label="Start Date"
                required
                autoOk
                maxDate={Now}
                minDate={MinDate}
                error={!!bag.errors.date}
                helperText={bag.errors.date}
                value={bag.values.date}
                disabled={bag.loading}
                onChange={(e: any) => {
                  const date = new Date(e);
                  date.setHours(0, 0, 0, 0);
                  setBag((b) => ({ ...b, values: { ...b.values, date } }));
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
        </Box>
      </MuiDialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onGoBack();
          }}
          color="primary"
          disabled={bag.loading}
        >
          Go Back
        </Button>
        <LoadableButton
          variant="contained"
          color="primary"
          autoFocus
          type="submit"
          loading={bag.loading}
          disabled={!linkToken || !bag.values.name || !bag.values.date}
        >
          Connect
        </LoadableButton>
      </DialogActions>
    </form>
  );
}

export interface PlaidAddIntegrationDialogProps {
  DialogTitle?: React.ComponentType<DialogTitleProps>;
  onClose: ((
    event: {},
    reason: "backdropClick" | "escapeKeyDown" | "onBack"
  ) => void) &
    ((event: {}, reason: "onFinish", accountName: string) => void);
}

function PlaidAddIntegrationDialog({
  DialogTitle = MuiDialogTitle,
  ...props
}: PlaidAddIntegrationDialogProps & Omit<DialogProps, "onClose">) {
  return (
    <Dialog
      TransitionProps={
        {
          tabIndex: "none",
        } as object
      }
      disableEnforceFocus
      {...props}
    >
      <PlaidAddIntegrationDialogContent
        DialogTitle={DialogTitle}
        onGoBack={() => props.onClose({}, "onBack")}
        onFinish={(name) => props.onClose({}, "onFinish", name)}
        type="expense"
      />
    </Dialog>
  );
}

export default PlaidAddIntegrationDialog;
