import firebase from "firebase/app";

import firestore from "src/apiService/firestore";
import storage from "src/apiService/storage";
import { userPlanSelector } from "src/store/system/selector";
import { getUploads } from "src/store/uploads/selector";
import type {
  PoshmarkIntegration,
  MercariIntegration,
} from "src/interfaces/plaidIntegration.interface";

import type { IsRunningKey } from "../types";

export const IsRunningThreshold = 60 * 1000; // 60 secs
export const ReSyncInterval = 24 * 60 * 60 * 1000; // 24hs
export const RunningKeys: IsRunningKey[] = [
  "inventoryIsRunning",
  "salesIsRunning",
];

export function checkIfItIsInitialImport(
  state: any,
  key:
    | "poshmarkSales"
    | "poshmarkInventory"
    | "mercariSales"
    | "mercariInventory"
) {
  const uploads = getUploads(state);

  for (const upload of uploads) {
    if (upload.filename?.includes(key)) return false;
  }

  return true;
}

export function isUserAllowedToDirectImport(
  state: any,
  key:
    | "poshmarkSales"
    | "poshmarkInventory"
    | "mercariSales"
    | "mercariInventory"
) {
  const plan = userPlanSelector(state);

  if (plan === "ultimate") return true;

  return checkIfItIsInitialImport(state, key);
}

type PoshmarkSyncDatePropertyName =
  | "poshmarkLastSyncDateInventory"
  | "poshmarkLastSyncDateTransactions";
type MercariSyncDatePropertyName =
  | "mercariLastSyncDateInventory"
  | "mercariLastSyncDateTransactions";

export async function saveSyncDate(
  integrationId: string,
  propertyName: PoshmarkSyncDatePropertyName | MercariSyncDatePropertyName,
  date: string
) {
  const db = firestore();
  await db
    .collection("Plaid_Integrations")
    .doc(integrationId)
    .update({
      [propertyName]: date,
    });
}

export function parseDate(
  date: string | undefined | null,
  fallback: Date
): Date {
  if (date) {
    const d = new Date(date || "");
    if (!isNaN(d.getTime())) return d;
  }

  return fallback;
}

export function getLastSyncDate(
  integrationData: MercariIntegration,
  propertyName: MercariSyncDatePropertyName
);
export function getLastSyncDate(
  integrationData: PoshmarkIntegration,
  propertyName: PoshmarkSyncDatePropertyName
);
export function getLastSyncDate(integrationData, propertyName) {
  const lastDate = integrationData[propertyName];
  if (lastDate?.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)) return lastDate;
  const date = new Date();
  const match = lastDate?.match(/(\d{4})-(\d{2})-(\d{2})/);
  if (match) {
    date.setFullYear(parseInt(match[1], 10));
    date.setMonth(parseInt(match[2], 10) - 1);
    date.setDate(parseInt(match[3], 10));
  } else {
    date.setDate(date.getDate() - 1);
  }
  date.setHours(0, 0, 0, 0);
  return date.toISOString();
}

export async function canRunSync(integrationId: string, keys: IsRunningKey[]) {
  const db = firestore();

  return await db.runTransaction(async (t) => {
    const now = Date.now();
    const ref = db.collection("Plaid_Integrations").doc(integrationId);
    const doc = await t.get(ref);
    if (!doc.exists)
      throw new Error(`Integration ${integrationId} doesn't exist`);
    const data = doc.data();
    if (!data) throw new Error(`Integration ${integrationId} doesn't exist`);
    const update = {};
    for (const key of keys) {
      if (key) {
        if (data[key] && data[key] + IsRunningThreshold > now) return false;
        update[key] = now;
      }
    }

    if (Object.keys(update).length <= 0) return false;

    await t.update(ref, update);
    return true;
  });
}

export async function shouldRunDailySync(
  integrationId: string,
  reSyncInterval: number
) {
  const db = firestore();

  return await db.runTransaction(async (t) => {
    const doc = await t.get(
      db.collection("Plaid_Integrations").doc(integrationId)
    );
    const data = doc.data();
    const lastSync = parseDate(
      data?.lastSync,
      new Date("2000-01-01T01:00:00.000Z")
    );
    return Date.now() - lastSync.getTime() >= reSyncInterval;
  });
}

export function setRunningInterval(
  integrationId: string,
  keys: IsRunningKey[],
  ms = 10000
): ReturnType<typeof setInterval> {
  const db = firestore();
  return setInterval(async () => {
    const now = Date.now();
    const doc = db.collection("Plaid_Integrations").doc(integrationId);
    await doc.update(
      keys.reduce((u, k) => {
        u[k] = now;
        return u;
      }, {} as object)
    );
  }, ms);
}

export async function clearIsRunning(
  integrationId: string,
  keys: IsRunningKey[]
) {
  const db = firestore();
  await db
    .collection("Plaid_Integrations")
    .doc(integrationId)
    .update(
      keys.reduce((a, k) => {
        a[k] = firebase.firestore.FieldValue.delete();
        return a;
      }, {} as Record<string, any>)
    );
}

export function setBeforeUnloadHandler(platform: string = "Poshmark") {
  const beforeunloadhandler = ((window as any)._beforeonloadhandler = (e) => {
    e.preventDefault();
    const text = `${platform} sync is currently running, are you sure to leave My Reseller Genie?`;
    e.returnValue = text;
    return text;
  });
  window.addEventListener("beforeunload", beforeunloadhandler);
}

export function clearBeforeUnloadHandler() {
  if ((window as any)._beforeonloadhandler) {
    window.removeEventListener(
      "beforeunload",
      (window as any)._beforeonloadhandler
    );
    delete (window as any)._beforeonloadhandler;
  }
}

export function calculateGrossProfit(item: any) {
  return (
    (item.sale_price || 0) -
    (item.purchase_price || 0) -
    (item.shipping_cost || 0) -
    (item.extra_shipping_cost || 0) -
    (item.extra_transaction_fees || 0) -
    (item.shipping_cost_analytics || 0) -
    (item.transaction_fees || 0) -
    (item.other_fees || 0)
  );
}

export function daysOnPlatform(
  saleDate?: Date,
  listDate?: Date
): number | null {
  if (
    !saleDate ||
    !listDate ||
    isNaN(saleDate.getTime()) ||
    isNaN(listDate.getTime())
  )
    return null;

  return Math.floor(
    (saleDate.getTime() - listDate.getTime()) / (1000 * 60 * 60 * 24)
  );
}

export async function uploadFile(filename: string, content: any) {
  const storageRef = storage().ref();
  const file = storageRef.child(filename);
  const task = await file.put(
    new Blob([content], { type: "application/json" })
  );

  if (task.state !== firebase.storage.TaskState.SUCCESS)
    throw new Error(`Couldnt upload file ${filename}: ${task.state}`);

  return true;
}

function round(number, fixed = 2) {
  const e = Math.pow(10, fixed);
  return Math.round(number * e) / e;
}

export class Allocator {
  value: number;
  divider: number;
  length: number;
  fixed: number;
  allocated: number;
  currentIndex = 0;

  /**
   * @param {number} value - total "discount"
   * @param {number} divider - eg.: total price
   * @param {number} length - amount of items
   * @param {number} fixed - decimal rounding
   */
  constructor(value: number, divider: number, length: number, fixed: number) {
    this.value = value;
    this.divider = divider;
    this.length = length;
    this.fixed = fixed;
    this.allocated = 0;
    this.currentIndex = 0;
  }

  /**
   * @param {number} dividend - eg.: price
   * @param {number} index - index of element
   * @return {number} allocated value
   */
  getAllocation(dividend) {
    const index = this.currentIndex;
    this.currentIndex += 1;
    if (!this.divider) return this.value;

    const last = index === this.length - 1;
    if (last) return this.value - this.allocated;

    let allocated = (this.value * dividend) / this.divider;

    if (this.fixed) allocated = round(allocated, this.fixed);

    this.allocated += allocated;
    return allocated;
  }
}
