import { createSelector } from "reselect";
import { getUploads } from "../uploads/selector";
import { salesSelector } from "../sale/selector";
import { getInventory } from "../inventory/selector";
import { getTransactionState } from "../transaction/selector";
import { userTrialEndDateSelector } from "../system/selector";

import type { Upload } from "src/interfaces/upload.interface";

// Seconds
const TimeEstimations = {
  Manual: {
    Sale: 56,
    Fee: 44,
    Inventory: 80,
    Refund: 58,
    PlaidTransaction: 30,
  },
  MRG: {
    Sale: 11,
    Fee: 0,
    Inventory: 26,
    Refund: 5,
    PlaidTransaction: 18,
  },
};

type ImportType = "initial-import" | "direct-import" | "daily-sync" | "upload";

function getUploadType<T extends { file?: string; upload?: string }>(
  uploads: Upload[],
  uploadType: string,
  regex: RegExp,
  item: T
): ImportType {
  const providers: Record<string, boolean> = {};

  for (const upload of uploads) {
    if (!upload.filename) continue;
    if (!upload.type?.toLowerCase().includes(uploadType)) continue;

    let provider = upload.provider;
    if (!provider) {
      const match = upload.filename.match(regex);
      if (match) provider = match[1].toLowerCase();
    }

    if (upload.filename === item.file || upload.id === item.upload) {
      if (!provider) return "upload";

      if (providers[provider]) return "direct-import";

      return "initial-import";
    }

    if (provider) providers[provider] = true;
  }

  return "daily-sync";
}

function safeLowerOrEqualThan(
  a: Date | undefined | null,
  b: Date | undefined | null
): boolean {
  if (!a || !b) return false;
  return a < b;
}

export const getTrialEstimationsSelector = createSelector(
  [
    getUploads,
    salesSelector,
    getInventory,
    getTransactionState,
    userTrialEndDateSelector,
  ],
  (uploads, salesState, inventoryItems, transactionState, trialEndDate) => {
    let totalTime = 0;
    let anualTotalTime = 0;
    let mrgTime = 0;
    let anualMrgTime = 0;
    const startTrialDate = new Date(trialEndDate || 0);
    startTrialDate.setDate(startTrialDate.getDate() - 14); // The trial lasts 14 days

    const sortedUploads = uploads.filter((u) => u.timestamp);
    sortedUploads.sort((a, b) => a.timestamp - b.timestamp);

    const sales = salesState.items;

    for (const sale of sales) {
      const key =
        sale.is_return || sale.original_id
          ? ("Refund" as const)
          : ("Sale" as const);
      if (sale.import_type) {
        if (sale.import_type !== "initial-import") {
          mrgTime += TimeEstimations.MRG[key];
          totalTime += TimeEstimations.Manual[key];

          if (
            safeLowerOrEqualThan(startTrialDate, sale.import_date?.toDate())
          ) {
            anualMrgTime += TimeEstimations.MRG[key];
            anualTotalTime += TimeEstimations.Manual[key];
          }
        }
      } else if (sale.file || sale.upload) {
        // Old import:
        // Daily syncs won't create an Upload document
        //  - eBay sets a `file` property with the filename for direct imports and daily sync.
        //    direct imports path: `<uid>/ebaySales_<timestamp>.json`
        //    daily sync path `<uid>/ebaySales/<integration id>_<timestamp>.json`
        //  - poshmark daily sync sets a `file` property with the filename
        //    direct import sets an `upload` property with the id of the upload
        //  - mercari daily sync sets a `file` property with the filename
        //    direct import sets an `upload` property with the id of the upload
        //  - upload: sale should have an `upload` property, but, the Upload document shouldn't derive a provider
        const importType = getUploadType(
          uploads,
          "sale",
          /\/(.*?)Sales[_/]/,
          sale
        );

        if (importType === "direct-import" || importType === "daily-sync") {
          mrgTime += TimeEstimations.MRG[key];
          totalTime += TimeEstimations.Manual[key];

          if (safeLowerOrEqualThan(startTrialDate, sale.sale_date)) {
            anualMrgTime += TimeEstimations.MRG[key];
            anualTotalTime += TimeEstimations.Manual[key];
          }
        }
      }

      if (
        sale.inventory_import_type &&
        sale.inventory_import_type !== "initial-import"
      ) {
        mrgTime += TimeEstimations.MRG.Inventory;
        totalTime += TimeEstimations.Manual.Inventory;

        if (
          safeLowerOrEqualThan(
            startTrialDate,
            sale.inventory_import_date?.toDate()
          )
        ) {
          anualMrgTime += TimeEstimations.MRG.Inventory;
          anualTotalTime += TimeEstimations.Manual.Inventory;
        }
      }
    }

    for (const inventory of inventoryItems) {
      if (inventory.import_type) {
        if (inventory.import_type !== "initial-import") {
          mrgTime += TimeEstimations.MRG.Inventory;
          totalTime += TimeEstimations.Manual.Inventory;

          if (
            safeLowerOrEqualThan(
              startTrialDate,
              inventory.import_date?.toDate()
            )
          ) {
            anualMrgTime += TimeEstimations.MRG.Inventory;
            anualTotalTime += TimeEstimations.Manual.Inventory;
          }
        }
      } else if (
        inventory.file ||
        inventory.upload ||
        inventory.download_timestamp
      ) {
        // Old import
        // Daily syncs won't create an Upload document
        //  - eBay direct import sets a `file` property with the filename for direct imports
        //    direct imports path: `<uid>/ebayInventory_<timestamp>.json`
        //  - poshmark direct import sets an `upload` property with the id of the upload
        //  - mercari direct import sets an `upload` property with the id of the upload
        //  - upload: inventory should have an `upload` property, but, the Upload document shouldn't derive a provider
        //  - All daily syncs will have an 'download_timestamp'
        const importType = inventory.download_timestamp
          ? "daily-sync"
          : getUploadType(
              uploads,
              "inventory",
              /\/(.*?)Inventory[_/]/,
              inventory
            );

        if (importType === "direct-import" || importType === "daily-sync") {
          mrgTime += TimeEstimations.MRG.Inventory;
          totalTime += TimeEstimations.Manual.Inventory;

          if (safeLowerOrEqualThan(startTrialDate, inventory.list_date)) {
            anualMrgTime += TimeEstimations.MRG.Inventory;
            anualTotalTime += TimeEstimations.Manual.Inventory;
          }
        }
      }
    }

    const transactions = transactionState.items;

    for (const transaction of transactions) {
      if (transaction.import_type) {
        if (transaction.import_type !== "initial-import") {
          const key =
            transaction.import_platform === "plaid"
              ? ("PlaidTransaction" as const)
              : ("Fee" as const);
          mrgTime += TimeEstimations.MRG[key];
          totalTime += TimeEstimations.Manual[key];

          if (
            safeLowerOrEqualThan(
              startTrialDate,
              transaction.import_date?.toDate()
            )
          ) {
            anualMrgTime += TimeEstimations.MRG[key];
            anualTotalTime += TimeEstimations.Manual[key];
          }
        }
      } else if (transaction.transaction_id || transaction.file) {
        // Old import:
        // Daily syncs won't create an Upload document
        //  - eBay sets a `file` property with the filename for direct imports and daily sync.
        //    direct imports path: `<uid>/ebaySales_<timestamp>.json`
        //    daily sync path `<uid>/ebaySales/<integration id>_<timestamp>.json`
        //  - poshmark and mercari do not create transactions
        //  - plaid: will have a `transaction_id` property but not a `file` one
        //  - uploads will have an `upload` property,
        if (!transaction.upload) {
          const key = transaction.file
            ? ("Fee" as const)
            : ("PlaidTransaction" as const);
          const importType =
            key === "PlaidTransaction"
              ? "daily-sync"
              : getUploadType(uploads, "sale", /\/(.*?)Sales[_/]/, transaction);

          if (importType === "direct-import" || importType === "daily-sync") {
            mrgTime += TimeEstimations.MRG[key];
            totalTime += TimeEstimations.Manual[key];

            if (safeLowerOrEqualThan(startTrialDate, transaction.date)) {
              anualMrgTime += TimeEstimations.MRG[key];
              anualTotalTime += TimeEstimations.Manual[key];
            }
          }
        }
      }
    }

    const days = Math.min(
      (Date.now() - startTrialDate.valueOf()) / (24 * 60 * 60 * 1000),
      14
    );

    return {
      anualEstTimeSavings: Math.round(
        ((anualTotalTime - anualMrgTime) * 365) / (days - 1)
      ),
      totalTime,
      mrgTime,
    };
  }
);
