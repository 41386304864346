import auth from "src/apiService/auth";

export const getAuthToken = async (): Promise<string> => {
  const authApi = auth();
  const user = authApi.currentUser;
  if (!user) {
    return new Promise((resolve, reject) => {
      authApi.onAuthStateChanged((gotUser) => {
        if (gotUser) {
          resolve(gotUser.getIdToken());
        } else {
          reject();
        }
      });
    });
  }
  return user.getIdToken();
};

export async function getUserId(): Promise<string> {
  const authApi = auth();
  const user = authApi.currentUser;
  if (!user) {
    return new Promise((resolve, reject) => {
      authApi.onAuthStateChanged(
        (gotUser) => {
          if (gotUser) {
            resolve(gotUser.uid);
            return;
          }
          reject(new Error("No user logged in"));
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  return user.uid;
}

export const getUserIdSync = (): string => {
  const authApi = auth();
  const user = authApi.currentUser;
  return user?.uid || " ";
};

export function getUserEmailSync(): string {
  const authApi = auth();
  const user = authApi.currentUser;
  return user?.email || "";
}
