import React, { useState } from "react";
import { useDispatch } from "react-redux";

import capitalize from "lodash/capitalize";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import SyncIcon from "@material-ui/icons/Sync";

import { StatefulLoadableIconButton } from "src/components/LoadableIconButton";
import {
  setIntegrationSync,
  dispatchIntegrationImport,
} from "src/store/support/actions";

import type {
  SupportStateUserDataIntegration,
  SupportStateUserDataIntegrationEBay,
  SupportStateUserDataIntegrationPoshmark,
  SupportStateUserDataIntegrationMercari,
} from "src/interfaces/supportState.interface";

import IntegrationImportDialog from "./IntegrationImportDialog";

export interface IntegrationsProps {
  integrations: SupportStateUserDataIntegration[];
}

function IntegrationEBayRow({
  integration,
}: {
  integration: SupportStateUserDataIntegrationEBay;
}) {
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState<"open" | "loading" | null>(null);
  return (
    <>
      <IntegrationImportDialog
        open={!!dialog}
        loading={dialog === "loading"}
        onClose={() => setDialog(null)}
        onImport={async (dataType, dates) => {
          setDialog("loading");
          if (dataType === "Inventory")
            await dispatch(
              dispatchIntegrationImport(
                integration.id,
                "direct-import-inventory"
              )
            );
          else if (dates)
            await dispatch(
              dispatchIntegrationImport(
                integration.id,
                "direct-import-sales",
                dates
              )
            );
          setDialog(null);
        }}
      />
      <TableRow>
        <TableCell>eBay</TableCell>
        <TableCell>{integration.username}</TableCell>
        <TableCell align="center">
          <Button color="primary" onClick={() => setDialog("open")}>
            Import
          </Button>
        </TableCell>
        <TableCell align="center">
          <Checkbox
            checked={integration.sync}
            onChange={(ev) => {
              const checked = ev.target.checked;
              dispatch(
                setIntegrationSync(integration.id, {
                  sync: checked,
                })
              );
            }}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            checked={integration.sync && integration.inventory !== false}
            disabled={!integration.sync}
            onChange={(ev) => {
              const checked = ev.target.checked;
              dispatch(
                setIntegrationSync(integration.id, {
                  inventory: checked,
                })
              );
            }}
          />
        </TableCell>
        <TableCell align="center">
          <StatefulLoadableIconButton
            onClick={() =>
              dispatch(dispatchIntegrationImport(integration.id, "daily-sync"))
            }
            color="primary"
          >
            <SyncIcon />
          </StatefulLoadableIconButton>
        </TableCell>
      </TableRow>
    </>
  );
}

function IntegrationClientRow({
  integration,
}: {
  integration:
    | SupportStateUserDataIntegrationPoshmark
    | SupportStateUserDataIntegrationMercari;
}) {
  const dispatch = useDispatch();
  return (
    <TableRow>
      <TableCell>{capitalize(integration.type)}</TableCell>
      <TableCell>{integration.username}</TableCell>
      <TableCell align="center">
        <Tooltip title="Can't dispatch an import for a client integration">
          <span>
            <Button color="primary" disabled>
              Import
            </Button>
          </span>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Checkbox
          checked={integration.sync}
          onChange={(ev) => {
            const checked = ev.target.checked;
            dispatch(
              setIntegrationSync(integration.id, {
                sync: checked,
              })
            );
          }}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          checked={integration.sync && integration.inventory !== false}
          disabled={!integration.sync}
          onChange={(ev) => {
            const checked = ev.target.checked;
            dispatch(
              setIntegrationSync(integration.id, {
                inventory: checked,
              })
            );
          }}
        />
      </TableCell>
      <TableCell align="center">
        <Tooltip title="Can't dispatch an import for a client integration">
          <span>
            <IconButton disabled color="primary">
              <SyncIcon />
            </IconButton>
          </span>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

function Integrations({ integrations }: IntegrationsProps) {
  const validIntegrations = integrations.filter(
    (i) => i.type === "ebay" || i.type === "poshmark" || i.type === "mercari"
  );
  return (
    <Box mx={2} my={1}>
      <Typography variant="h6">Platform Integrations</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Platform</TableCell>
            <TableCell>Username</TableCell>
            <TableCell align="center">Import Past Data</TableCell>
            <TableCell align="center">
              Daily Integration - Sales and Fees
            </TableCell>
            <TableCell align="center">Daily Integration - Inventory</TableCell>
            <TableCell align="center">Pull Data Now</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {validIntegrations.length > 0 ? (
            validIntegrations.map((integration) =>
              integration.type === "ebay" ? (
                <IntegrationEBayRow
                  key={integration.id}
                  integration={integration}
                />
              ) : integration.type === "poshmark" ||
                integration.type === "mercari" ? (
                <IntegrationClientRow
                  key={integration.id}
                  integration={integration}
                />
              ) : null
            )
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={6}>
                Empty
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
}

export default Integrations;
