import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikHelpers as FormikActions, FormikProps } from "formik";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

import { type Theme } from "@material-ui/core";
import MuiLink from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";

import { AppState } from "../../store";
import { clearSignupStatus, signup } from "../../store/system/actions";
import { LOGO } from "../../assets/svgs";
import TermsDialog from "./terms";
import PrivacyDialog from "./privacy";

import LoadableButton from "src/components/LoadableButton";

const styles = (theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      padding: 12,
    },
  });

interface MyFormValues {
  accepted: boolean;
  email: string;
  password: string;
}

class SignupPage extends React.Component<
  any,
  { termsDialog: boolean; privacyDialog: boolean }
> {
  constructor(props: any) {
    super(props);
    this.onFormikSubmit = this.onFormikSubmit.bind(this);

    this.state = {
      termsDialog: false,
      privacyDialog: false,
    };
  }

  async onFormikSubmit(
    values: MyFormValues,
    { setSubmitting }: FormikActions<MyFormValues>
  ) {
    if (values.email !== "" && values.password !== "" && !this.props.loading) {
      this.props.clearSignupStatus();
      this.props.signup({ ...values });
    }
  }

  openTermsDialog = () =>
    this.setState({ termsDialog: true, privacyDialog: false });
  openPrivacyDialog = () =>
    this.setState({ privacyDialog: true, termsDialog: false });
  closeDialogs = () =>
    this.setState({ termsDialog: false, privacyDialog: false });

  render() {
    const { classes, loggedIn, loading, error } = this.props;
    if (loggedIn) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Box className="mb-5 h-36">
            <img src={LOGO} alt="My Reseller Genie" className="h-full" />
          </Box>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Formik
            initialValues={{
              accepted: false,
              password: "",
              email: "",
            }}
            onSubmit={this.onFormikSubmit}
          >
            {(formikBag: FormikProps<MyFormValues>) => (
              <form
                className={classes.form}
                noValidate
                onSubmit={formikBag.handleSubmit}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={formikBag.handleChange}
                  onBlur={formikBag.handleBlur}
                  value={formikBag.values.email}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={formikBag.handleChange}
                  onBlur={formikBag.handleBlur}
                  value={formikBag.values.password}
                />
                <FormControlLabel
                  checked={formikBag.values.accepted}
                  name="accepted"
                  onChange={() =>
                    formikBag.setFieldValue(
                      "accepted",
                      !formikBag.values.accepted
                    )
                  }
                  control={<Checkbox value="accepted" color="primary" />}
                  label={
                    <div>
                      I agree to the{" "}
                      <MuiLink
                        href="https://www.myresellergenie.com/terms-of-use"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Use
                      </MuiLink>{" "}
                      and{" "}
                      <MuiLink
                        href="https://www.myresellergenie.com/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </MuiLink>
                      .
                    </div>
                  }
                />
                {!!error && !loading && <Alert severity="error">{error}</Alert>}
                <LoadableButton
                  type="submit"
                  disabled={loading || !formikBag.values.accepted}
                  loading={loading}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign up
                </LoadableButton>
                <Grid container>
                  <Grid item>
                    <Link to="/login">Log in</Link>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
        <TermsDialog
          open={this.state.termsDialog}
          onClose={this.closeDialogs}
        />
        <PrivacyDialog
          open={this.state.privacyDialog}
          onClose={this.closeDialogs}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ system }: AppState) => ({
  loggedIn: system.loggedIn,
  loading: system.loading,
  error: system.error,
});

const mapDispatchToProps = {
  clearSignupStatus,
  signup,
};

export default withStyles(styles)(
  connect<any, any, any, AppState>(
    mapStateToProps,
    mapDispatchToProps
  )(SignupPage)
);
