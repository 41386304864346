import React, { useEffect, useState, type ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import confetti from "canvas-confetti";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";

import { setActiveDialog } from "src/store/adminHtml/actions";
import LoadableButton from "src/components/LoadableButton";
import { poshmarkSetDeductionsRemindMeLaterAction } from "src/store/plaidIntegration/actions";
import { getPoshmarkIntegration } from "src/store/plaidIntegration/selector";
import { poshmarkUploadDeductionReport } from "src/apiService/modules/poshmark";

const useMoneyStyles = makeStyles({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "150px",
  },
  paper: {
    display: "inline-block",
    padding: "12px 0px",
    position: "relative",
    zIndex: 9999,
  },
  note: {
    "&::before": {
      content: '""',
      width: "50px",
      height: "50px",
      background: "rgba(0, 0, 0, 0.08)",
      display: "inline-block",
      verticalAlign: "middle",
      borderRadius: "50%",
      margin: "0 20px",
    },
    "&::after": {
      content: '""',
      width: "50px",
      height: "50px",
      background: "rgba(0, 0, 0, 0.08)",
      display: "inline-block",
      verticalAlign: "middle",
      borderRadius: "50%",
      margin: "0 20px",
    },
  },
  money: {
    padding: "12px 10px",
    fontWeight: "bold",
    fontSize: "40px",
    color: "rgba(0, 0, 0, 0.7)",
    borderTop: "4px solid rgba(0, 0, 0, 0.08)",
    borderBottom: "4px solid rgba(0, 0, 0, 0.08)",
    display: "inline-block",
    verticalAlign: "middle",
    position: "relative",
    fontVariantNumeric: "tabular-nums",
    "&::before": {
      content: "attr(data-currency)",
      position: "absolute",
      top: "12px",
      left: "-8px",
      fontSize: "20px",
    },
    "&::after": {
      content: "attr(data-cent)",
      fontSize: "22px",
      color: "rgba(0, 0, 0, 0.5)",
      fontVariantNumeric: "tabular-nums",
    },
  },
  background: {
    background: "#cfeed4",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    zIndex: -2,
  },
  shadow: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    zIndex: -2,
    "&::after": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",

      background: "#bbd9c0",
      top: "10px",
      left: "10px",
      animation:
        "$pulsingAfter 0.9s infinite alternate cubic-bezier(0.22, 0.61, 0.36, 1)",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",

      background: "#a3c4a9",
      top: "20px",
      left: "-10px",
      animation:
        "$pulsingBefore 0.9s infinite alternate cubic-bezier(0.22, 0.61, 0.36, 1)",
    },
  },
  "@keyframes pulsingBefore": {
    "0%": {
      top: 0,
      left: 0,
    },
    "100%": {
      top: "12px",
      left: "-6px",
    },
  },
  "@keyframes pulsingAfter": {
    "0%": {
      top: 0,
      left: 0,
    },
    "100%": {
      top: "6px",
      left: "6px",
    },
  },
});

const Milliseconds = 2000;
const Frame = 16;
function Money({ amount }: { amount: number }) {
  const classes = useMoneyStyles();
  const [currentAmount, setCurrentAmount] = useState(0);
  const floor = Math.floor(currentAmount);

  useEffect(() => {
    const scalar = 2;
    const money = confetti.shapeFromText({ text: "💵", scalar });
    const defaults = {
      spread: 360,
      ticks: 60,
      gravity: 0,
      decay: 0.96,
      startVelocity: 20,
      shapes: [money],
      scalar,
      zIndex: 1500,
    };

    const shoot = () => {
      confetti({
        ...defaults,
        particleCount: 30,
      });

      confetti({
        ...defaults,
        particleCount: 5,
        flat: true,
      });
    };

    setTimeout(shoot, 0);
    setTimeout(shoot, 300);
    setTimeout(shoot, 800);
    setTimeout(shoot, 1000);

    let cancel = false;
    const steps = Math.round(Milliseconds / Frame);
    const step = amount / steps;
    let timeout: ReturnType<typeof setTimeout> | undefined;
    const f = () => {
      if (cancel) return;
      setCurrentAmount((a) => {
        const n = a + step;
        return n > amount ? amount : n;
      });

      timeout = setTimeout(f, Frame);
    };

    f();
    return () => {
      cancel = true;
      clearTimeout(timeout);
    };
  }, [amount]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.paper}>
        <div className={classes.shadow} />
        <div className={classes.background} />
        <div className={classes.note}>
          <div
            className={classes.money}
            data-cent={(currentAmount - floor).toFixed(2).substring(1)}
            data-currency="$"
          >
            {floor}
          </div>
        </div>
      </div>
    </div>
  );
}

function Content({
  onClose,
  cancel,
}: {
  onClose: () => void;
  cancel?: boolean;
}) {
  const dispatch = useDispatch();
  const integration = useSelector(getPoshmarkIntegration);
  const [state, setState] = useState<{
    file?: File;
    isSubmitting: boolean;
    error?: string;
    result?: {
      amount: number | string;
      date: string;
    };
  }>({ isSubmitting: false });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input: HTMLInputElement = e.target;
    if (input?.files?.[0]) {
      const file = input?.files?.[0];
      setState((s) => ({ ...s, file }));
      (e.target.value as any) = null;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const file = state.file;
    if (!file) return;
    setState(({ error, ...s }) => ({ ...s, isSubmitting: true }));
    try {
      const report = await new Promise<string>((rs) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.addEventListener("load", (e) => {
          rs((reader.result as string) || "");
        });
      });
      const result = await poshmarkUploadDeductionReport({ report });
      setState((s) => ({
        ...s,
        ...result,
        isSubmitting: false,
      }));
    } catch (e) {
      setState((s) => ({
        ...s,
        isSubmitting: false,
        error: (e as Error).message || (e as Error).toString(),
      }));
    }
  };

  const handleRemindMeLater = () => {
    dispatch(poshmarkSetDeductionsRemindMeLaterAction());
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  if (state.result) {
    const amount = parseFloat(state.result.amount as any);
    const fixed = amount.toFixed(2);
    const date = new Date(state.result.date).toLocaleDateString();
    return (
      <>
        <DialogTitle>Deductions Insight</DialogTitle>
        <DialogContent>
          {fixed === "0.00" ? (
            <DialogContentText>
              Your Poshmark shipping costs have been updated as of {date}. There
              weren’t any additional shipping costs to be added this time. We’ll
              remind you to do this again next quarter or you can manually
              trigger the update on the “Integrations” tab.
            </DialogContentText>
          ) : (
            <>
              <DialogContentText>
                Congrats! You just obtained an additional ${fixed} in
                deductions.
              </DialogContentText>
              <DialogContentText>
                Your Poshmark shipping costs have been updated as of {date}.
                We’ll remind you to do this again next quarter or you can
                manually trigger the update on the “Integrations” tab.
              </DialogContentText>
              <Money amount={amount} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={onClose}>
            Awesome!
          </Button>
        </DialogActions>
      </>
    );
  }

  const lastImportEndDate = integration.deductionsReportImport
    ? integration.deductionsReportImport.end.toDate().toLocaleDateString()
    : "01/01/2024";

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle>Deductions Insight</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Hey there! There may be some deductions that you’re missing for
          Poshmark shipping that are not shown on Poshmark’s website.
        </DialogContentText>
        <DialogContentText>
          You can add these shipping costs to your sales very quickly, by
          downloading a sales report from Poshmark for {lastImportEndDate}{" "}
          through today and uploading here.
        </DialogContentText>
        {state.file ? (
          <Box mb={1.5}>
            <TextField
              disabled={state.isSubmitting}
              value={state.file.name}
              fullWidth
              label="Selected file"
              variant="outlined"
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setState(({ file, ...s }) => s);
                      }}
                      disabled={state.isSubmitting}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        ) : (
          <DialogContentText>
            <Button
              component="label"
              color="primary"
              disabled={state.isSubmitting}
            >
              Click to Upload a File
              <input
                accept="text/csv"
                style={{ display: "none" }}
                type="file"
                onChange={handleChange}
              />
            </Button>
          </DialogContentText>
        )}
        {state.error ? <Alert severity="error">{state.error}</Alert> : null}
      </DialogContent>
      <DialogActions>
        {cancel ? (
          <Button
            color="primary"
            disabled={state.isSubmitting}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        ) : (
          <Button
            color="primary"
            disabled={state.isSubmitting}
            onClick={handleRemindMeLater}
          >
            Remind Me Later
          </Button>
        )}
        <LoadableButton
          color="primary"
          autoFocus
          variant="contained"
          loading={state.isSubmitting}
          type="submit"
          disabled={!state.file}
        >
          Upload
        </LoadableButton>
      </DialogActions>
    </form>
  );
}

function PoshmarkDeductionsDialog({
  open,
  cancel,
}: {
  open: boolean;
  cancel?: boolean;
}) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setActiveDialog(""));
  };

  return (
    <Dialog open={open}>
      <Content onClose={handleClose} cancel={cancel} />
    </Dialog>
  );
}

export default PoshmarkDeductionsDialog;
