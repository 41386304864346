import { toast } from "react-toastify";
import {
  SupportStateUserToken,
  SupportStateUserData,
  SupportStateEBayLimits,
} from "src/interfaces/supportState.interface";
import MonthYear from "src/interfaces/monthYear.interface";
import {
  supportGenerateToken,
  supportGetUser,
  supportUpdateUser,
  SupportUpdateUser,
  supportGetEBayLimits,
  supportSetIntegrationSync,
  supportDispatchIntegrationImport,
} from "src/apiService/modules/support";
import {
  supportGetToken,
  supportGetUser as selectorSupportGetUser,
} from "./selector";

export const SET_SUPPORT_LOGIN_TOKEN = "SET_SUPPORT_LOGIN_TOKEN";
export const SET_SUPPORT_USER_TOKEN = "SET_SUPPORT_USER_TOKEN";
export const SET_SUPPORT_USER_DATA = "SET_SUPPORT_USER_DATA";
export const UPDATE_SUPPORT_USER_INTEGRATION =
  "UPDATE_SUPPORT_USER_INTEGRATION";
export const SET_SUPPORT_EBAY_LIMITS = "SET_SUPPORT_EBAY_LIMITS";

interface SetSupportLoginToken {
  type: typeof SET_SUPPORT_LOGIN_TOKEN;
  token: string;
}

interface SetSupportUserToken {
  type: typeof SET_SUPPORT_USER_TOKEN;
  payload: SupportStateUserToken;
}

interface SetSupportUserData {
  type: typeof SET_SUPPORT_USER_DATA;
  payload: SupportStateUserData;
}

interface UpdateSupportUserData {
  type: typeof UPDATE_SUPPORT_USER_INTEGRATION;
  payload: { id: string; sync: boolean; inventory: boolean };
}

interface SetSupporEBayLimits {
  type: typeof SET_SUPPORT_EBAY_LIMITS;
  payload: SupportStateEBayLimits;
}

export type SupportActionTypes =
  | SetSupportLoginToken
  | SetSupportUserToken
  | SetSupportUserData
  | UpdateSupportUserData
  | SetSupporEBayLimits;

export function setSupportLoginToken(token = ""): SupportActionTypes {
  return {
    type: SET_SUPPORT_LOGIN_TOKEN,
    token,
  };
}

function setUserToken(payload: SupportStateUserToken): SupportActionTypes {
  return {
    type: SET_SUPPORT_USER_TOKEN,
    payload: payload,
  };
}

export function generateUserToken(username: string) {
  return (dispatch, getState) => {
    dispatch(setUserToken({ username, loading: true }));
    const token = supportGetToken(getState());
    supportGenerateToken(token, username)
      .then(({ data }) => {
        dispatch(
          setUserToken({
            username,
            value: data.token,
            exp: data.exp,
          })
        );
      })
      .catch((err) => {
        if (err.code === "failed-precondition")
          dispatch(setSupportLoginToken());

        dispatch(
          setUserToken({
            username,
            error: err.message,
          })
        );

        toast.error(err.message);
      });
  };
}

function setUserData(payload: SupportStateUserData): SupportActionTypes {
  return {
    type: SET_SUPPORT_USER_DATA,
    payload: payload,
  };
}

export function getUser(username: string) {
  return (dispatch, getState) => {
    dispatch(setUserData({ username, loading: true }));
    const token = supportGetToken(getState());
    supportGetUser(token, username)
      .then(({ data }) => {
        dispatch(
          setUserData({
            ...data.data,
            username,
          })
        );
      })
      .catch((err) => {
        if (err.code === "failed-precondition")
          dispatch(setSupportLoginToken());

        dispatch(
          setUserData({
            username,
            error: err.message,
          })
        );

        toast.error(err.message);
      });
  };
}

export function updateUser(username: string, data: SupportUpdateUser) {
  return (dispatch, getState) => {
    const user = selectorSupportGetUser(getState(), username);
    const token = supportGetToken(getState());
    dispatch(setUserData({ ...user, username, loading: true }));
    return supportUpdateUser(token, username, data)
      .then(({ data }) => {
        dispatch(
          setUserData({
            ...data.data,
            username,
          })
        );
      })
      .catch((err) => {
        if (err.code === "failed-precondition")
          dispatch(setSupportLoginToken());

        dispatch(
          setUserData({
            username,
            error: err.message,
          })
        );

        toast.error(err.message);
      });
  };
}

function setEBayLimits(payload: SupportStateEBayLimits): SupportActionTypes {
  return {
    type: SET_SUPPORT_EBAY_LIMITS,
    payload,
  };
}

export function getEBayLimits() {
  return (dispatch, getState) => {
    const token = supportGetToken(getState());
    dispatch(setEBayLimits({ loading: true }));
    return supportGetEBayLimits(token)
      .then(({ data }) => {
        dispatch(setEBayLimits({ limits: data.rateLimits }));
      })
      .catch((err) => {
        if (err.code === "failed-precondition")
          dispatch(setSupportLoginToken());

        dispatch(
          setEBayLimits({
            error: err.message,
          })
        );

        toast.error(err.message);
      });
  };
}

export function setIntegrationSync(
  id: string,
  args: { sync?: boolean; inventory?: boolean }
) {
  return (dispatch, getState) => {
    const token = supportGetToken(getState());
    return supportSetIntegrationSync(token, id, args).then(({ data }) => {
      dispatch({
        type: UPDATE_SUPPORT_USER_INTEGRATION,
        payload: {
          id,
          sync: data.sync,
          inventory: data.inventory,
        },
      });
    });
  };
}

export function dispatchIntegrationImport(
  integrationId: string,
  importJob: "direct-import-inventory"
);
export function dispatchIntegrationImport(
  integrationId: string,
  importJob: "daily-sync"
);
export function dispatchIntegrationImport(
  integrationId: string,
  importJob: "direct-import-sales",
  args: { start: MonthYear; end: MonthYear }
);
export function dispatchIntegrationImport(
  integrationId: string,
  importJob: "direct-import-inventory" | "daily-sync" | "direct-import-sales",
  args?: { start: MonthYear; end: MonthYear }
) {
  return (dispatch, getState) => {
    const token = supportGetToken(getState());

    // The only way to get arround ts compiler is managing cases individually
    // Overloads are not too good in ts :(
    if (importJob === "direct-import-sales") {
      if (!args) throw Error("`args` must be passed");
      return supportDispatchIntegrationImport(
        token,
        integrationId,
        importJob,
        args
      );
    }

    if (importJob === "daily-sync")
      return supportDispatchIntegrationImport(token, integrationId, importJob);

    return supportDispatchIntegrationImport(token, integrationId, importJob);
  };
}
