import { createSelector } from "reselect";
import { AppState } from "..";
import type { AdminHtmlState } from "../../interfaces";
import type { Alert } from "src/interfaces/alert.interface";
import type { User } from "src/interfaces/systemState.interface";
import {
  getUnreviewedReturnSales,
  getUnreviewedSales,
} from "src/store/sale/selector";
import { selectUser } from "src/store/system/selector";
import {
  getNewSyncSalesDownloads,
  getNewSyncInventoryDownloads,
} from "src/store/uploads/selector";
import { getNewErrorAlerts } from "src/store/errorAlerts/selector";
import { getTransactions } from "src/store/transaction/selector";
import { getUnreviewedCashActivities } from "src/store/cashActivity/selector";
import {
  getEBayIntegrations,
  getPoshmarkIntegrations,
  getAmazonIntegrations,
} from "src/store/plaidIntegration/selector";
import { getUserEmailSync } from "src/config/storage";

const getAdminHtmlState = (state: AppState) => state.adminHtml;

export const adminHtmlSelector = createSelector(
  [getAdminHtmlState],
  (adminHtml: AdminHtmlState) => adminHtml
);

export const activeDialogSelector = createSelector(
  [adminHtmlSelector],
  ({ activeDialog }) => activeDialog || ""
);
export const activeDialogPropsSelector = createSelector(
  [adminHtmlSelector],
  ({ activeDialogProps }) => activeDialogProps
);

function buildZendeskLink(
  user: User,
  subject: string,
  description: string,
  errorAlertId: string
): string {
  const email = getUserEmailSync();
  const name = [user.firstName, user.lastName].filter(Boolean).join(" ");
  const inventory =
    user.inventoryType === "cash"
      ? "cash_inventory_method"
      : "accrual_inventory_method";
  const plan =
    user.plan === "ultimate"
      ? "ultimate"
      : user.plan === "premium"
      ? "premium"
      : "basic";
  return (
    "https://myresellergenie.zendesk.com/hc/en-us/requests/new?" +
    new URLSearchParams({
      tf_anonymous_requester_email: email,
      tf_33056527918491: name,
      tf_subject: subject,
      tf_description: description,
      tf_33056851616411: "other",
      tf_33056407563803: inventory,
      tf_33056476777243: plan,
      tf_33117710457115: errorAlertId,
    }).toString()
  );
}

export const alertsSelector = createSelector(
  [
    selectUser,
    getUnreviewedSales,
    getNewSyncSalesDownloads,
    getNewSyncInventoryDownloads,
    getNewErrorAlerts,
    getTransactions,
    getUnreviewedCashActivities,
    getUnreviewedReturnSales,
    getEBayIntegrations,
    getPoshmarkIntegrations,
    getAmazonIntegrations,
  ],
  (
    user,
    unreviewedSales,
    newSyncSalesDownloads,
    newSyncInventoryDownloads,
    errorAlerts,
    transactionState,
    unreviewedCashActivities,
    unreviewedReturns,
    ebayIntegrations,
    poshmarkIntegrations,
    amazonIntegrations
  ): Alert[] => {
    const alerts: Alert[] = [];

    if (user?.Plaid_transactions_error)
      alerts.push({
        type: "plaid_error",
        message:
          "There were errors syncing Plaid transactions. Click here to reconnect.",
      });

    if (unreviewedSales.length)
      alerts.push({
        type: "review_sales",
        message: "Click here to review new sales.",
      });

    if (newSyncSalesDownloads.length)
      alerts.push({
        type: "sales",
        message: "You have new eBay transactions ready to import.",
      });

    if (newSyncInventoryDownloads.length)
      alerts.push({
        type: "inventory",
        message: "Click here to review pending inventory.",
      });

    if (errorAlerts?.length) {
      for (const errorAlert of errorAlerts) {
        if (errorAlert.type === "ebay-rate-limit") {
          if (!user) continue;
          alerts.push({
            type: "error-link",
            message: errorAlert.message,
            id: errorAlert.id,
            href: buildZendeskLink(
              user,
              "eBay Setup Help",
              "Hello, can you help me get my eBay data imported? I received a notification that one of my imports contained too many items to complete on my end.",
              errorAlert.id
            ),
          });
        } else if (
          errorAlert.type === "general-error-notification-form-submission"
        ) {
          if (!user) continue;
          const subject =
            errorAlert.formSubject ||
            [errorAlert.platform, "Error Message"].filter(Boolean).join(" ");
          const description =
            errorAlert.formDescription ||
            [
              "Hello, I received a notification that there was an error related to my",
              errorAlert.platform,
              "integration. Can you look into this for me?",
            ]
              .filter(Boolean)
              .join(" ");

          alerts.push({
            type: "error-link",
            message:
              errorAlert.displayMessage ||
              [
                "There was an error importing your",
                errorAlert.platform,
                "data. Click here to message support for a resolution.",
              ]
                .filter(Boolean)
                .join(" "),
            id: errorAlert.id,
            href: buildZendeskLink(user, subject, description, errorAlert.id),
          });
        } else {
          alerts.push({
            type: "error",
            message: errorAlert.message,
            id: errorAlert.id,
          });
        }
      }
    }

    if (transactionState.unreviewedTransactions.length)
      alerts.push({
        type: "expenses",
        message: "Click here to review new expenses.",
      });

    if (unreviewedCashActivities.length)
      alerts.push({
        type: "cash_activities",
        message: "Click here to review new cash activities.",
      });

    if (unreviewedReturns?.length > 0)
      alerts.push({
        type: "returns",
        message: "Click here to review new returns/refunds/cancellations.",
      });

    for (const integration of ebayIntegrations) {
      if (!integration.error) continue;
      alerts.push({
        integrationId: integration.id,
        type: "ebay_error",
        message: `My Reseller Genie has stopped syncing with your eBay ${
          integration.id === "ebay" ? "" : `${integration.name} `
        }account. Click here to restore the connection.`,
      });
    }

    for (const integration of amazonIntegrations) {
      if (!integration.error) continue;
      alerts.push({
        integrationId: integration.id,
        type: "amazon_error",
        message: `My Reseller Genie has stopped syncing with your ${
          integration.username || ""
        } Amazon account. Click here to restore the connection.`,
      });
    }

    for (const integration of poshmarkIntegrations) {
      if (integration.error) {
        alerts.push({
          integrationId: integration.id,
          type: "poshmark_error",
          message: `My Reseller Genie has stopped syncing with your ${integration.username} Poshmark account. Click here to restore the connection.`,
        });
      }

      if (
        !integration.deductionsRemindLater ||
        integration.deductionsRemindLater.toDate() < new Date()
      ) {
        alerts.push({
          type: "poshmark_deductions",
          message:
            "There may be some deductions that you’re missing for Poshmark shipping that are not shown on Poshmark’s website. Click here to review them.",
        });
      }
    }

    return alerts;
  }
);
