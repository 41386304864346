import firebase from "firebase/app";
import "firebase/functions";

import type { EBayLimit } from "src/interfaces/supportState.interface";
import type MonthYear from "src/interfaces/monthYear.interface";

function supportLogin(username: string, password: string) {
  const fn = firebase.functions().httpsCallable("support-login");
  return fn({ username, password });
}

function supportGenerateToken(token: string, username: string) {
  const fn = firebase.functions().httpsCallable("support-generateToken");
  return fn({ token, username });
}

async function supportGetUser(token: string, username: string) {
  const fn = firebase.functions().httpsCallable("support-getUser");
  return await fn({ token, username });
}

export interface SupportUpdateUser {
  email?: string;
  emailVerified?: boolean;
  disabled?: boolean;
}

async function supportUpdateUser(
  token: string,
  username: string,
  data: SupportUpdateUser
) {
  const fn = firebase.functions().httpsCallable("support-updateUser");
  return await fn({ ...data, username, token });
}

async function supportGetEBayLimits(
  token: string
): Promise<{ data: { rateLimits: EBayLimit } }> {
  const fn = firebase.functions().httpsCallable("support-getEBayLimits");
  return await fn({ token });
}

async function supportSetIntegrationSync(
  token: string,
  id: string,
  { sync, inventory }: { sync?: boolean; inventory?: boolean }
): Promise<{ data: { id: string; sync: boolean; inventory: boolean } }> {
  const fn = firebase.functions().httpsCallable("support-setIntegrationSync");
  return await fn({ token, id, sync, inventory });
}

async function supportDispatchIntegrationImport(
  token: string,
  integrationId: string,
  importJob: "direct-import-inventory"
);
async function supportDispatchIntegrationImport(
  token: string,
  integrationId: string,
  importJob: "daily-sync"
);
async function supportDispatchIntegrationImport(
  token: string,
  integrationId: string,
  importJob: "direct-import-sales",
  args: { start: MonthYear; end: MonthYear }
);
async function supportDispatchIntegrationImport(
  token: string,
  integrationId: string,
  importJob: "direct-import-inventory" | "daily-sync" | "direct-import-sales",
  args?: { start: MonthYear; end: MonthYear }
) {
  const fn = firebase
    .functions()
    .httpsCallable("support-dispatchIntegrationImport");
  return await fn({ token, integrationId, importJob, args });
}

export {
  supportLogin,
  supportGenerateToken,
  supportGetUser,
  supportUpdateUser,
  supportGetEBayLimits,
  supportSetIntegrationSync,
  supportDispatchIntegrationImport,
};
