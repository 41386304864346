import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "firebase/storage";
import "firebase/firestore";
import firestore from "src/apiService/firestore";

import { fixSalesFilter } from "src/enums";
import { getUserId } from "src/config";
import { setActiveDialog } from "src/store/adminHtml/actions";
import {
  addBankStatementRecords,
  updateBankStatementRecords,
  deleteBankStatementRecords,
} from "src/store/bankStatement/actions";
import {
  addCashActivityRecords,
  updateCashActivityRecords,
  deleteCashActivityRecords,
} from "src/store/cashActivity/actions";
import {
  addSalesRecords,
  updateSalesRecords,
  deleteSalesRecords,
  setTimeFilterIfNecessary as setTimeFilter,
} from "src/store/sale/actions";
import {
  addInventoryRecords,
  updateInventoryRecords,
  deleteInventoryRecords,
} from "src/store/inventory/actions";
import {
  addNumericOverviewRecords,
  updateNumericOverviewRecords,
  deleteNumericOverviewRecords,
} from "src/store/numericOverview/actions";
import {
  addTransactionRecords,
  updateTransactionRecords,
  deleteTransactionRecords,
} from "src/store/transaction/actions";
import {
  addUploadRecords,
  updateUploadRecords,
  deleteUploadRecords,
  addDownloadRecords,
  updateDownloadRecords,
  deleteDownloadRecords,
} from "src/store/uploads/actions";
import { setUser } from "src/store/system/actions";
import {
  addErrorAlertRecords,
  deleteErrorAlertRecords,
  updateErrorAlertRecords,
} from "src/store/errorAlerts/actions";
import {
  addPlaidIntegrationRecords,
  deletePlaidIntegrationRecords,
  updatePlaidIntegrationRecords,
} from "src/store/plaidIntegration/actions";
import {
  addGeneralLedgerAccounts,
  deleteGeneralLedgerAccounts,
  updateGeneralLedgerAccounts,
} from "src/store/glAccounts/actions";
import {
  addVendors,
  deleteVendors,
  updateVendors,
} from "src/store/vendor/actions";
import { increaseTourCounter } from "src/apiService/modules/users";

async function wrapListener<T>(label: string, p: Promise<T>): Promise<T> {
  console.time(label);
  try {
    return await p;
  } finally {
    console.timeEnd(label);
  }
}

function startListener(
  dispatch,
  signal: AbortSignal,
  collectionName: string,
  userId: string,
  addAction,
  updateAction,
  deleteAction,
  callback?: (notEmpty: boolean) => void
) {
  let first = true;
  const db = firestore();
  return new Promise<void>((rs, rj) => {
    const listener = db
      .collection(collectionName)
      .where("user", "==", userId)
      .onSnapshot(
        (snapshot) => {
          if (signal.aborted) return;
          console.log(`received ${collectionName} snapshot`);
          const adds: any[] = [];
          const changes: any[] = [];
          const deletes: any[] = [];
          snapshot.docChanges().forEach((change) => {
            const data = change.doc.data();
            if (data && !data.id) data.id = change.doc.id;
            if (change.type === "added") {
              adds.push(data);
            } else if (change.type === "modified") {
              changes.push(data);
            } else if (change.type === "removed") {
              deletes.push(data);
            }
          });
          if (adds.length) dispatch(addAction(adds));
          if (updateAction && changes.length) dispatch(updateAction(changes));
          if (deletes.length) dispatch(deleteAction(deletes));

          if (callback)
            callback(!adds.length && !changes.length && !deletes.length);

          if (first) {
            first = false;
            rs();
          }
        },
        (error) => {
          console.error(
            `Snapshot error when reading from ${collectionName} collection as user ${userId}`,
            error
          );
          if (first) {
            first = false;
            rj(error);
          }
        }
      );
    signal.addEventListener("abort", () => listener());
  });
}

async function startListeners(dispatch, signal: AbortSignal) {
  const userId = await wrapListener("useUserDataLoader:getUserId", getUserId());
  const db = firestore();

  let tourCount = 0;
  let tourDisplayed = false;
  let hasShownNumericOverviewError = false;
  await wrapListener(
    "useUserDataLoader:user",
    new Promise<void>((rs, rj) => {
      const listener = db
        .collection("Users")
        .doc(userId)
        .onSnapshot(
          (doc: any) => {
            if (signal.aborted) return;

            if (!doc.exists) {
              console.warn(`User document '${userId}' doesn't exist`);
              return;
            }

            const receivedUser = doc.data();
            tourCount = receivedUser.tour_count || 0;

            rs();

            if (!receivedUser.acceptedTOS)
              dispatch(setActiveDialog("accept_terms"));

            if (
              receivedUser &&
              !hasShownNumericOverviewError &&
              receivedUser.noError
            ) {
              hasShownNumericOverviewError = true;
              toast.error(
                "Your financials report couldn't be calculated because some of your data is off. Reach out to support@myresellergenie.com to resolve this issue."
              );
            }

            receivedUser.sales_filter = fixSalesFilter(
              receivedUser.sales_filter
            );
            dispatch(setUser(receivedUser));
            dispatch(setTimeFilter(receivedUser.sales_filter));
          },
          (error) => {
            console.error(
              `Snapshot error when reading user data for user ${userId}.`,
              error
            );
            rj(error);
          }
        );

      signal.addEventListener("abort", () => listener());
    })
  );

  if (signal.aborted) return;

  await Promise.allSettled([
    wrapListener(
      "useUserDataLoader:Sales",
      startListener(
        dispatch,
        signal,
        "Sales",
        userId,
        addSalesRecords,
        updateSalesRecords,
        deleteSalesRecords
      )
    ),
    wrapListener(
      "useUserDataLoader:Inventory",
      startListener(
        dispatch,
        signal,
        "Inventory",
        userId,
        addInventoryRecords,
        updateInventoryRecords,
        deleteInventoryRecords
      )
    ),
    wrapListener(
      "useUserDataLoader:Bank_Statements",
      startListener(
        dispatch,
        signal,
        "Bank_Statements",
        userId,
        addBankStatementRecords,
        updateBankStatementRecords,
        deleteBankStatementRecords
      )
    ),
    wrapListener(
      "useUserDataLoader:Cash_Activities",
      startListener(
        dispatch,
        signal,
        "Cash_Activities",
        userId,
        addCashActivityRecords,
        updateCashActivityRecords,
        deleteCashActivityRecords
      )
    ),
    wrapListener(
      "useUserDataLoader:Transactions",
      startListener(
        dispatch,
        signal,
        "Transactions",
        userId,
        addTransactionRecords,
        updateTransactionRecords,
        deleteTransactionRecords
      )
    ),
    wrapListener(
      "useUserDataLoader:Uploads",
      startListener(
        dispatch,
        signal,
        "Uploads",
        userId,
        addUploadRecords,
        updateUploadRecords,
        deleteUploadRecords
      )
    ),
    wrapListener(
      "useUserDataLoader:Downloads",
      startListener(
        dispatch,
        signal,
        "Downloads",
        userId,
        addDownloadRecords,
        updateDownloadRecords,
        deleteDownloadRecords
      )
    ),
    wrapListener(
      "useUserDataLoader:GL_Accounts",
      startListener(
        dispatch,
        signal,
        "GL_Accounts",
        userId,
        addGeneralLedgerAccounts,
        updateGeneralLedgerAccounts,
        deleteGeneralLedgerAccounts
      )
    ),
    wrapListener(
      "useUserDataLoader:Plaid_Integrations",
      startListener(
        dispatch,
        signal,
        "Plaid_Integrations",
        userId,
        addPlaidIntegrationRecords,
        updatePlaidIntegrationRecords,
        deletePlaidIntegrationRecords
      )
    ),
    wrapListener(
      "useUserDataLoader:Error_Alerts",
      startListener(
        dispatch,
        signal,
        "Error_Alerts",
        userId,
        addErrorAlertRecords,
        updateErrorAlertRecords,
        deleteErrorAlertRecords
      )
    ),
    wrapListener(
      "useUserDataLoader:Vendors",
      startListener(
        dispatch,
        signal,
        "Vendors",
        userId,
        addVendors,
        updateVendors,
        deleteVendors
      )
    ),
    wrapListener(
      "useUserDataLoader:Numeric_Overview",
      startListener(
        dispatch,
        signal,
        "Numeric_Overview",
        userId,
        addNumericOverviewRecords,
        updateNumericOverviewRecords,
        deleteNumericOverviewRecords,
        (empty) => {
          // XXX: this isn't exactly the best approach, it should be probably fixed in the future
          if (tourDisplayed) return;
          tourDisplayed = true;

          if (empty) {
            dispatch(addNumericOverviewRecords([]));
            dispatch(setActiveDialog("empty_tables"));
          } else if (tourCount < 3) {
            tourCount++;
            increaseTourCounter();
            dispatch(setActiveDialog("zendesk_help_tour"));
          }
        }
      )
    ),
  ]);
}

function useUserDataLoader() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    const abortController = new AbortController();
    (async () => {
      try {
        await wrapListener(
          "useUserDataLoader",
          startListeners(dispatch, abortController.signal)
        );
      } catch (error) {
        console.trace(error);
      }
      if (abortController.signal.aborted) return;
      setIsLoading(false);
    })();

    return () => {
      console.log("useUserDataLoader - abort");
      abortController.abort();
    };
  }, [dispatch]);

  return isLoading;
}

export default useUserDataLoader;
