import React, { useEffect } from "react";
import { Route, Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "react-router-dom";

import Tap from "@tapfiliate/tapfiliate-js";

import { TAPFILIATE_ACCOUNT_ID } from "../config";
import AdminHtml from "../layouts/AdminHtml";
import LogOutPage from "../pages/LogOutPage";

import GetStartedPage from "../pages/GetStarted";
import ManagementPage from "../pages/ManagementPage";
import SalesPage from "../pages/SalesPage";
import InventoryPage from "../pages/InventoryPage";
import CashReconciliationPage from "../pages/CashReconciliationPage";
import ReportsPage from "../pages/ReportsPage";
import TransactionPage from "../pages/TransactionPage";
import IntegrationsPage from "../pages/Integrations";

import AddInventoryDialog from "../pages/RootDialogs/AddInventoryDialog";
import EnterPaymentMethodDialog from "../pages/RootDialogs/EnterPaymentMethodDialog";
import EmptyTablesDialog from "../pages/RootDialogs/EmptyTablesDialog";
import ZendeskHelpTour from "src/pages/RootDialogs/ZendeskHelpTour";
import DuplicateInventoryChecker from "../pages/RootDialogs/DuplicateInventoryChecker";
import ReportSaleDialog from "../pages/RootDialogs/ReportSaleDialog";
import ManageUploadsDialog from "../pages/RootDialogs/Uploads/ManageUploadsDialog";
import SalesMatching from "src/pages/RootDialogs/SalesMatching";
import SalesReview from "../pages/RootDialogs/SalesReview";

import WalkthroughDialog from "../pages/RootDialogs/WalkthroughDialog";
import UpgradePlanDialog from "src/pages/RootDialogs/UpgradePlanDialog";
import SetUpFeatureDialog from "src/pages/RootDialogs/SetUpFeatureDialog";
import MaintenanceActivitiesDialog from "src/pages/RootDialogs/MaintenanceActivitiesDialog";
import AddExpenseDialog from "src/pages/RootDialogs/AddExpenseDialog";
import WalkThroughNotificationsIconDialog from "src/pages/RootDialogs/WalkThroughNotificationsIconDialog";
import IntegrationSyncerDialog from "src/pages/RootDialogs/IntegrationSyncerDialog";
import PoshmarkDeductionsDialog from "src/pages/RootDialogs/PoshmarkDeductionsDialog";

import { WALKTHROUGH_IDS } from "../store/adminHtml/data";
import { activeDialogSelector } from "../store/adminHtml/selector";
import {
  tapfiliateSelector,
  userCustomerIdSelector,
  selectUser,
} from "../store/system/selector";
import CategorizeExpensesDialog from "../pages/RootDialogs/CategorizeExpensesDialog";
import AddCashActivitiesDialog from "../pages/RootDialogs/AddCashActivitiesDialog";
import AddIntegrationAccountDialog from "../pages/RootDialogs/AddIntegrationAccountDialog";
import AcceptTermsDialog from "../pages/RootDialogs/AcceptTermsDialog";
import AddGeneralLedgerAccountDialog from "../pages/RootDialogs/AddGeneralLedgerAccountDialog";
import ReviewReturnsDialog from "src/pages/RootDialogs/ReviewReturnsDialog";
import UpdateIntegrationAccountDialog from "src/pages/RootDialogs/UpdateIntegrationAccountDialog";

import { userGetStartedHasFinishedSelector } from "src/store/system/selector";
import { userChecklistCompletedSelector } from "src/store/system/selector";

import { AppLoader } from "src/components/AppLoader";

import useUserDataLoader from "src/utils/useUserDataLoader";
import { clearSignupStatus } from "src/store/system/actions";

function DefaultRedirection() {
  const hasFinished = useSelector(userGetStartedHasFinishedSelector);
  const checklistCompleted = useSelector(userChecklistCompletedSelector);
  const user = useSelector(selectUser);
  if (!user) return null;
  return (
    <Redirect
      to={hasFinished || checklistCompleted ? "/management" : "/get-started"}
    />
  );
}

const PrivateRouter = () => {
  const dispatch = useDispatch();
  const activeDialog = useSelector(activeDialogSelector);
  const tapfiliateReady = useSelector(tapfiliateSelector);
  const userCustomerId = useSelector(userCustomerIdSelector);

  const isUserDataLoading = useUserDataLoader();

  useEffect(() => {
    if (userCustomerId && tapfiliateReady) {
      const referralCode = localStorage.getItem("referralCode");
      if (referralCode) {
        Tap.init(TAPFILIATE_ACCOUNT_ID, { integration: "stripe" }, null, {
          referral_code: referralCode,
        });
        Tap.trial(userCustomerId);
      }
      dispatch(clearSignupStatus());
    }
  });

  if (isUserDataLoading) return <AppLoader />;

  return (
    <>
      <Switch>
        <Route exact path="/logout" component={LogOutPage} />
        <Route path="/get-started" exact>
          <GetStartedPage />
        </Route>
        <Route path="/management" exact>
          <AdminHtml
            pageTitle="Management Dashboard"
            pageDescription="An overview of your numbers"
            walkthroughId={WALKTHROUGH_IDS.MANAGEMENT}
          >
            <ManagementPage />
          </AdminHtml>
        </Route>
        <Route path="/analytics" exact>
          <AdminHtml
            pageTitle="Sales"
            pageDescription="Powerful analytics (premium and ultimate only) and a detail of sales"
            walkthroughId={WALKTHROUGH_IDS.SALES}
          >
            <SalesPage />
          </AdminHtml>
        </Route>
        <Route path="/inventory" exact>
          <AdminHtml
            pageTitle="Inventory"
            pageDescription="A detail of inventory"
            walkthroughId={WALKTHROUGH_IDS.INVENTORY}
          >
            <InventoryPage />
          </AdminHtml>
        </Route>
        <Route path="/cash" exact>
          <AdminHtml
            pageTitle="Cash Reconciliation"
            pageDescription="Track the money your business has"
            walkthroughId={WALKTHROUGH_IDS.CASH_RECONCILIATION}
          >
            <CashReconciliationPage />
          </AdminHtml>
        </Route>
        <Route path="/transaction" exact>
          <AdminHtml
            pageTitle="Expenses"
            pageDescription="A detail of business expenses"
            walkthroughId={WALKTHROUGH_IDS.EXPENSE_DETAIL}
          >
            <TransactionPage />
          </AdminHtml>
        </Route>
        <Route path="/reports" exact>
          <AdminHtml
            pageTitle="Reseller Reports"
            pageDescription="Compile Your Business Data"
          >
            <ReportsPage />
          </AdminHtml>
        </Route>
        <Route path="/integrations" exact>
          <IntegrationsPage />
        </Route>
        <Route component={DefaultRedirection} />
      </Switch>
      <WalkthroughDialog open={activeDialog === "walkthrough"} />
      <ReportSaleDialog open={activeDialog === "report_sale"} />
      <AddInventoryDialog open={activeDialog === "add_inventory"} />
      <ManageUploadsDialog open={activeDialog === "manage_uploads"} />
      <EmptyTablesDialog open={activeDialog === "empty_tables"} />
      <ZendeskHelpTour open={activeDialog === "zendesk_help_tour"} />
      <SalesMatching open={activeDialog === "match_sales"} />
      <SalesReview open={activeDialog === "review_sales"} />
      <DuplicateInventoryChecker open={activeDialog === "inventory_sync"} />
      <AddIntegrationAccountDialog
        open={activeDialog === "add_cash_account"}
        type={"cash"}
      />
      <UpdateIntegrationAccountDialog
        open={activeDialog === "update_expense_account"}
      />
      <CategorizeExpensesDialog open={activeDialog === "categorize_expenses"} />
      <AddCashActivitiesDialog open={activeDialog === "cash_activities"} />
      <AcceptTermsDialog open={activeDialog === "accept_terms"} />
      <ReviewReturnsDialog open={activeDialog === "returns_review"} />
      <AddGeneralLedgerAccountDialog open={activeDialog === "add_gl_account"} />
      <UpgradePlanDialog open={activeDialog === "upgrade_plan"} />
      <EnterPaymentMethodDialog />
      <SetUpFeatureDialog />
      <MaintenanceActivitiesDialog
        open={activeDialog === "maintenance_activities_dialog"}
      />
      <AddExpenseDialog open={activeDialog === "add_expense"} />
      <WalkThroughNotificationsIconDialog
        open={activeDialog === "walk_through_notifications_icon"}
      />
      <IntegrationSyncerDialog />
      <PoshmarkDeductionsDialog open={activeDialog === "poshmark_deductions"} />
      <PoshmarkDeductionsDialog
        open={activeDialog === "poshmark_update_shipping"}
        cancel
      />
    </>
  );
};

export default PrivateRouter;
