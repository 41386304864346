import React, { useMemo } from "react";
import type { XGridProps, GridColDef } from "@material-ui/x-grid";
import { useSelector, useDispatch } from "react-redux";
import produce from "immer";
import { tablesConfigurationSelector } from "src/store/system/selector";
import { updateTableColumn } from "src/store/system/actions";
import XGrid from "src/components/EnhacedXGrid";

export interface ConfiguredXGridProps extends XGridProps {
  tableName: string;
  Component?: React.ComponentType<XGridProps>;
}

function getKey(col: GridColDef): string {
  return col.field;
}

function ConfiguredXGrid({
  tableName,
  columns,
  Component = XGrid,
  ...props
}: ConfiguredXGridProps) {
  const dispatch = useDispatch();
  const tableConfiguration = useSelector(tablesConfigurationSelector);
  const config = tableConfiguration[tableName];

  const configuredColumns = useMemo(() => {
    if (!config) return columns;
    let cols = produce(columns, (columns) => {
      const hide = config.hide;
      const width = config.width;
      for (const col of columns) {
        const key = getKey(col);
        if (hide && key in hide) col.hide = hide[key];
        if (width?.[key]) col.width = width[key];
      }
    });

    const order = config.order;
    if (order) {
      cols = [...cols];
      const sortedColumns: typeof cols = [];
      for (let i = 0; i < cols.length; i++) {
        const col = cols[i];
        const key = getKey(col);
        let index = order[key];
        if (index !== undefined) {
          while (sortedColumns[index]) {
            index++;
          }
          sortedColumns[index] = col;
          delete cols[i];
        }
      }
      for (let i = 0; i < cols.length; i++) {
        const col = cols[i];
        if (!col) continue;
        let ii = 0;
        while (sortedColumns[ii]) {
          ii++;
        }
        sortedColumns[ii] = col;
      }
      cols = sortedColumns.filter(Boolean);
    }

    return cols;
  }, [columns, config]);

  return (
    <Component
      {...props}
      onColumnWidthChange={({ colDef, width }) => {
        const key = getKey(colDef);
        dispatch(updateTableColumn(tableName, "width", { column: key, width }));
      }}
      onColumnOrderChange={({ colDef, targetIndex, oldIndex }) => {
        const key = getKey(colDef);
        dispatch(
          updateTableColumn(tableName, "order", {
            column: key,
            target: targetIndex,
            old: oldIndex,
          })
        );
      }}
      onColumnVisibilityChange={({ colDef, isVisible }) => {
        const key = getKey(colDef);
        dispatch(
          updateTableColumn(tableName, "visibility", {
            column: key,
            visibility: isVisible,
          })
        );
      }}
      columns={configuredColumns}
    />
  );
}

function ConfiguredXGridWrapper({
  tableName,
  ...props
}: Omit<ConfiguredXGridProps, "tableName"> &
  Partial<Pick<ConfiguredXGridProps, "tableName">>) {
  if (!tableName) return <XGrid {...props} />;

  return <ConfiguredXGrid tableName={tableName} {...props} />;
}

export default ConfiguredXGridWrapper;
