import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "src/pages/support/Login";
import { supportIsLoggedIn } from "src/store/support/selector";
import Support from "src/layouts/Support";
import Token from "src/pages/support/Token";
import User from "src/pages/support/User";
import EBayLimits from "src/pages/support/EBayLimits";

function SupportRouter() {
  const isLoggedIn = useSelector(supportIsLoggedIn);

  if (!isLoggedIn) return <Login />;

  return (
    <Support>
      <Switch>
        <Route path="/support/ebay-limits" component={EBayLimits} />
        <Route path="/support/user/:uid?" component={User} />
        <Route component={Token} />
      </Switch>
    </Support>
  );
}

export default SupportRouter;
