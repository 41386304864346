import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setActiveDialog } from "src/store/adminHtml/actions";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import {
  fetchPlaidLinkToken,
  transferPlaidPublicToken,
} from "src/apiService/modules/plaid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { calculateRgba } from "react-spinners/helpers";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment/moment";
import clsx from "clsx";

const usePlaidButtonStyles = makeStyles({
  root: {
    position: "relative",
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-7px",
    marginleft: "-7px",
  },
});

function PlaidButton({
  className,
  loading,
  children,
  ...props
}: { loading?: boolean } & ButtonProps) {
  const classes = usePlaidButtonStyles();

  return (
    <Button
      disabled={loading}
      className={clsx(classes.root, className)}
      {...props}
    >
      {children}
      {loading && <CircularProgress size={14} className={classes.spinner} />}
    </Button>
  );
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    connectedPlaidButton: {
      background: calculateRgba("green", 0.12) + "!important",
    },
    clearDateButton: {
      alignSelf: "center",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "flex-end",
    },
  })
);

// type="expense" -> DEPRECATED
function AddIntegrationAccountDialogContent({
  onClose,
}: {
  onClose: () => void;
}) {
  const classes = useStyles();
  const [token, setToken] = useState(null);
  const [publicToken, setPublicToken] = useState<string | null>(null);
  const [accountName, setAccountName] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState<any>(null);
  const type = "cash";
  const title = type === "cash" ? "Add Cash Account" : "Add Expense Account";
  const plaidTitle =
    type === "cash" ? "Select Cash Account" : "Select Expense Account";

  useEffect(() => {
    if (token == null) {
      fetchPlaidLinkToken().then((result) => {
        setToken(result);
        localStorage.setItem("link_token", result);
        localStorage.setItem("type", type);
      });
    }
  }, [token, type]);

  const config: PlaidLinkOptions = {
    onSuccess: (public_token) => {
      setPublicToken(public_token || null);
    },
    onExit: (err) => {
      if (err) console.error(`Plaid encounters an error: ${err}`);
    },
    token,
  };

  const { open: openPlaid } = usePlaidLink(config);

  const openPlaidLink = () => {
    openPlaid();
  };

  const addExpenseAccount = async (event) => {
    if (!publicToken) return;
    setLoading(true);
    await transferPlaidPublicToken(publicToken, type, {
      name: accountName,
      date,
    });
    onClose();
  };

  const updateInputValue = (evt) => {
    const val = evt.target.value;
    setAccountName(val);
    localStorage.setItem("account_name", val);
  };

  return (
    <>
      <DialogTitle className="relative">{title}</DialogTitle>
      <DialogContent>
        <form>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Account Name"
                  variant="standard"
                  value={accountName}
                  required={true}
                  onChange={(evt) => updateInputValue(evt)}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.buttonContainer}>
                <PlaidButton
                  loading={loading || !config.token}
                  color="primary"
                  variant="contained"
                  onClick={openPlaidLink}
                  className={
                    publicToken ? classes.connectedPlaidButton : undefined
                  }
                >
                  {publicToken ? "Connected!" : plaidTitle}
                </PlaidButton>
              </Grid>
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  disableToolbar
                  className="w-full"
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="dense"
                  label="Date"
                  required={true}
                  autoOk={true}
                  value={date}
                  disabled={loading}
                  onChange={(e: any) => {
                    setDate(new Date(e));
                  }}
                  helperText={"Select a start date"}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  maxDate={new Date()}
                  minDate={moment().subtract(2, "years")}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.clearDateButton}>
                <Button
                  onClick={() => setDate(null)}
                  color="primary"
                  variant="contained"
                >
                  Clear Date
                </Button>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={addExpenseAccount}
          disabled={!date || !publicToken || !(accountName.length > 0)}
        >
          Add
        </Button>
      </DialogActions>
    </>
  );
}

function AddIntegrationAccountDialog({
  open,
  type,
}: {
  open: boolean;
  type: "cash";
}) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setActiveDialog(""));
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="body"
      fullWidth
      maxWidth="sm"
      TransitionProps={
        {
          tabIndex: "none",
        } as object
      }
      disableEnforceFocus // required due to how Plaid Link works, else will flicker.
    >
      <AddIntegrationAccountDialogContent onClose={handleClose} />
    </Dialog>
  );
}

export default AddIntegrationAccountDialog;
