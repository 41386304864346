import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { XGrid } from "@material-ui/x-grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

import { getEBayLimits } from "src/store/support/actions";
import {
  supportEBayLimitsLoadingSelector,
  supportEBayLimitsLimitsSelector,
} from "src/store/support/selector";

const Columns = [
  {
    field: "apiName",
    headerName: "API name",
    flex: 1,
  },
  {
    field: "name",
    headerName: "Resource Name",
    flex: 1,
  },
  {
    field: "remaining_percentage",
    headerName: "Remaining %",
    valueFormatter: (params) => params.row.remaining_percentage.toFixed(2),
    flex: 1,
  },
  {
    field: "remaining",
    headerName: "Remaining",
    flex: 1,
  },
  {
    field: "limit",
    headerName: "Limit",
    flex: 1,
  },
  {
    field: "reset",
    headerName: "Reset",
    flex: 1,
  },
];

function EBayLimits() {
  const dispatch = useDispatch();
  const loading = useSelector(supportEBayLimitsLoadingSelector);
  const limits = useSelector(supportEBayLimitsLimitsSelector);

  useEffect(() => {
    dispatch(getEBayLimits());
  }, [dispatch]);

  const rows = useMemo(() => {
    const rows = limits.reduce((rows, limit) => {
      for (const resource of limit.resources) {
        if (resource.rates) {
          if (resource.rates.length === 1) {
            const rate = resource.rates[0];
            rows.push({
              ...limit,
              ...resource,
              ...rate,
              id: `${limit.apiVersion}/${limit.apiContext}/${limit.apiName}/${resource.name}`,
              remaining_percentage: (rate.remaining * 100) / rate.limit,
            });
          } else {
            console.warn("Resource rate length != 1", limit, resource);
          }
        }
      }
      return rows;
    }, [] as any[]);

    rows.sort((a, b) => a.remaining_percentage - b.remaining_percentage);

    return rows;
  }, [limits]);

  return (
    <Box m={2} height="100%" component={Paper}>
      <XGrid columns={Columns} rows={rows} loading={loading} />
    </Box>
  );
}

export default EBayLimits;
