import produce from "immer";
import { handleActions } from "redux-actions";
import { normalize } from "src/apiService/modules/analyticsGroupings";


import { SystemState } from "../../interfaces";
import { FAIL, START, SUCCESS } from "../common";
import {
  CLEAR_SIGNUP_STATUS,
  CLEAR_SUBSCRIPTION_RESPONSE,
  FETCH_LOGIN,
  LOGIN_WITH_TOKEN,
  SET_USER,
  LOG_OUT,
  RESET_PASSWORD,
  SIGNUP,
  UPDATE_USER,
  UPDATE_TABLE_COLUMN,
  UPDATE_ANALYTICS_GROUPINGS,
} from "./actions";

const initialState: SystemState = {
  loggedIn: false,
  user: undefined,
  loading: false,
  error: undefined,
  authorizing: false,
  passwordResetSubmitted: false,
  submitting: false,
  submitted: false,
  ebayAuthUrl: "",
  ebayAuthLoading: false,
  ebayAuth: null,
  ebayIntegration: false,
  signupSuccess: false,
};

export const systemReducer = handleActions<SystemState, any>(
  {
    [FETCH_LOGIN + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.error = undefined;
      }),
    [LOGIN_WITH_TOKEN + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.error = undefined;
      }),
    [FETCH_LOGIN + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loggedIn = true;
        draft.error = undefined;
        draft.user = payload.user;
        draft.authorizing = false;
      }),
    [FETCH_LOGIN + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loggedIn = false;
        draft.error = payload;
        draft.user = undefined;
      }),
    [SIGNUP + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.error = undefined;
        draft.authorizing = true;
      }),
    [SIGNUP + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loggedIn = true;
        draft.error = undefined;
        draft.user = payload.user;
        draft.authorizing = false;
        draft.signupSuccess = true;
      }),
    [SIGNUP + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.authorizing = false;
        draft.loading = false;
        draft.loggedIn = false;
        draft.error = payload;
        draft.user = undefined;
      }),
    [SET_USER]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.user = payload;
        draft.ebayIntegration = !!payload.ebayIntegration;
      }),
    [RESET_PASSWORD + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
      }),
    [RESET_PASSWORD + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.passwordResetSubmitted = true;
      }),
    [RESET_PASSWORD + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [UPDATE_USER + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.error = false;
      }),
    [UPDATE_USER + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
    [UPDATE_USER + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = false;
      }),
    [CLEAR_SIGNUP_STATUS]: (state) =>
      produce(state, (draft) => {
        draft.signupSuccess = false;
        draft.error = false;
      }),
    [CLEAR_SUBSCRIPTION_RESPONSE]: (state) =>
      produce(state, (draft) => {
        if (draft.user) {
          draft.user.subscriptionResponse = null;
        }
      }),
    [LOG_OUT]: () => initialState,
    [UPDATE_TABLE_COLUMN]: (state, { payload: { table, config } }) =>
      produce(state, (draft) => {
        if (draft.user) {
          if (!draft.user.tables_configuration)
            draft.user.tables_configuration = {};
          draft.user.tables_configuration[table] = JSON.stringify(config);
        }
      }),
    [UPDATE_ANALYTICS_GROUPINGS]: (state, { payload }) =>
    produce(state, (draft) => {
      if (draft.user?.analytics_groupings) {
        if ('insert' in payload && payload.insert) {
          const department = normalize(payload.insert.department);
          if (!draft.user.analytics_groupings[department])
            draft.user.analytics_groupings[department] = {};
          const category = normalize(payload.insert.category);
          if (!draft.user.analytics_groupings[department][category])
            draft.user.analytics_groupings[department][category] = [];
          const subcategory = normalize(payload.insert.subcategory);
          if (!draft.user.analytics_groupings[department][category].includes(subcategory))
            draft.user.analytics_groupings[department][category].push(subcategory)
        }

        // TODO: delete
      }
    }),
  },
  initialState
);
