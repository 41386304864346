import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import {
  LicenseInfo,
  GridSelectionModel,
} from "@material-ui/x-grid";
import XGrid from "src/components/ConfiguredXGrid";
import CSVFormattedXGrid from "src/components/CSVFormattedXGrid";
import { createTableToolbar } from "src/components/TableToolbar";

import { makeStyles } from "@material-ui/core/styles";

import ConfirmDialog from "src/components/ConfirmDialog";
import EditDialog from "./EditDialog";
import InfoTooltip from "../../components/InfoTooltip";

import { clearUpdateMethod } from "../../store/common";
import { updateItem, setFilter } from "../../store/inventory/actions";
import { inventoriesSelector } from "../../store/inventory/selector";

import { formatCurrency } from "../../utils";
import { Inventory } from "../../interfaces";
import { METHOD_TYPE } from "../../enums";
import { X_GRID_LICENSE_KEY } from "../../config";
import { deleteBulkInventoryApi } from "src/apiService/modules/inventories";
import BulkEditDialog from "./BulkEditDialog";
import vendorRenderCell from "src/utils/vendorRenderCell";
import renderHeaderDescriptionLabel from "src/utils/renderHeaderLabel";

LicenseInfo.setLicenseKey(X_GRID_LICENSE_KEY);

const TableToolbar = createTableToolbar({
  options: { fileName: "My Reseller Genie - Inventory" }
});

const tableColumnInfo = {
  location: "Where you have stored the item.",
  purchase_date: "The date that you purchased this item.",
  list_date: "The date that you listed this item for sale.",
  purchase_price: "The amount you paid for the inventory.",
  notes: "Enter any other information that you need in here.",
};

const renderHeaderLabel = (params) => {
  const tooltip = tableColumnInfo[params.field];
  return (
    <div className="MuiDataGrid-columnHeaderTitle">
      {params.colDef.headerName}
      {!!tooltip && <InfoTooltip size="small" text={tooltip} />}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: `1px solid #f0f0f0`,
      padding: "0px 10px",
      fontSize: "12px",
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid #f0f0f0`,
    },
    "& .MuiDataGrid-cell": {
      color: `rgba(0,0,0,.85)`,
    },
    "& .MuiDataGrid-iconSeparator": {
      opacity: 0,
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#fafafa",
      borderTop: "1px solid #f0f0f0",
      fontSize: "12px",
    },
    "& .MuiDataGrid-colCell": {
      borderRight: `1px solid #f0f0f0`,
      padding: "0px 10px",
    },
  },
});

const Columns = [
  {
    field: "id",
    hide: true,
    valueFormatter: ({ value }) => {
      if (typeof value === "number" || !value.match || !value.match(/[^0-9]/))
        return `id:${value}`;
      return value;
    },
  },
  { field: "item_title", headerName: "Item Title", width: 200 },
  {
    field: "quantity",
    headerName: "Quantity",
    width: 120,
    valueGetter: (params) => params.value || 1,
  },
  { field: "department", headerName: "Department", width: 150 },
  { field: "category", headerName: "Category", width: 200 },
  { field: "sub_category", headerName: "Sub Category", width: 200 },
  {
    field: "vendor",
    headerName: "Vendor",
    width: 200,
    renderCell: vendorRenderCell,
    renderHeader: renderHeaderDescriptionLabel,
    description: "The vendor is where you purchased the item (ex. Goodwill).",
    valueGetter: (params) => params.row.vendorName || params.value || "",
  },
  { field: "brand", headerName: "Brand", width: 200 },
  {
    field: "location",
    headerName: "Location",
    width: 300,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "sku",
    headerName: "SKU",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "purchase_price",
    headerName: "Purchase Price",
    width: 200,
    type: "number",
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "purchase_date",
    headerName: "Purchase Date",
    width: 200,
    type: "date",
    renderHeader: renderHeaderLabel,
  },
  {
    field: "list_date",
    headerName: "List Date",
    width: 200,
    type: "date",
    renderHeader: renderHeaderLabel,
  },
  {
    field: "platforms_listed",
    headerName: "Platforms Listed",
    width: 300,
    renderHeader: renderHeaderLabel,
    valueFormatter: ({ value }) => {
      if (value && value.length) {
        return `${value.join(", ")}`;
      }
      return "";
    },
  },
  {
    field: "notes",
    headerName: "Notes",
    width: 300,
    renderHeader: renderHeaderLabel,
  },
];

export const Content = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    items: initialItems,
    method,
    loading,
    error,
    // limit,
    filter: filterModel,
    // selected: selectionModel,
  } = useSelector(inventoriesSelector);
  const items = initialItems || [];
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const [openEdit, setOpenEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [openUploadBulk, setOpenUploadBulk] = useState(false);

  useEffect(() => {
    const isModalAction =
      method === METHOD_TYPE.UPDATE || method === METHOD_TYPE.DELETE;
    if (!error && !loading && isModalAction) {
      setOpenEdit(false);
      dispatch(clearUpdateMethod());
    }
  }, [loading]);

  const handleCloseEdit = () => {
    if (
      (method === METHOD_TYPE.UPDATE || method === METHOD_TYPE.DELETE) &&
      loading
    )
      return;
    setOpenEdit(false);
  };

  const handleSelectItem = (selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  };

  const getSelectedItem = (sel: any[]) => {
    if (!sel || !sel.length) return;
    return items.find((item) => item.id === sel[0]);
  };

  const handleSaveItem = (item: Inventory) => {
    dispatch(updateItem(item));
  };

  const handleSelectAndEdit = (e: any) => {
    setSelectionModel([e.row.id]);
    setOpenEdit(true);
  };

  const handleChangeFilterModel = (e: any) => {
    dispatch(setFilter(e));
  };

  const handleDeleteSelected = async () => {
    setShowDelete(false);
    if (selectionModel.length > 0) {
      await deleteBulkInventoryApi(selectionModel.map(String));
      setSelectionModel([]);
      setOpenEdit(false);
    }
  };

  const handleCloseUploadBulk = () => {
    setOpenUploadBulk(false);
  };

  const handleOpenUploadBulk = () => {
    setOpenUploadBulk(true);
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <div>
          <Paper className="p-5 h-auto">
            <div className="flex justify-between flex-wrap mb-3">
              <h2 className="text-left text-blue mb-0">Inventory Detail</h2>
              <div className={"flex items-center"}>
                <Button
                  variant="contained"
                  color="primary"
                  className="h-9 ml-3 mt-1"
                  onClick={handleOpenUploadBulk}
                >
                  Bulk Edit Inventory
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className="h-9 ml-3 mt-1"
                  disabled={loading || selectionModel.length === 0}
                  onClick={() => {
                    setShowDelete(true);
                  }}
                  style={{ marginRight: 10 }}
                >
                  Delete Selected
                </Button>
              </div>
            </div>
            <XGrid
              checkboxSelection
              checkboxSelectionVisibleOnly
              tableName="inventory"
              Component={CSVFormattedXGrid}
              className="h-table"
              rows={items}
              columns={Columns}
              headerHeight={35}
              rowHeight={35}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50, 100]}
              pagination
              loading={loading && method === METHOD_TYPE.LIST}
              components={{
                Toolbar: TableToolbar,
              }}
              filterModel={filterModel}
              selectionModel={selectionModel}
              onSelectionModelChange={handleSelectItem}
              onPageSizeChange={(pageSize) => {
                setPageSize(pageSize);
              }}
              onCellDoubleClick={handleSelectAndEdit}
              onFilterModelChange={handleChangeFilterModel}
            />
            <EditDialog
              data={getSelectedItem(selectionModel)}
              open={openEdit}
              loading={loading}
              onClose={handleCloseEdit}
              onSave={handleSaveItem}
              onDelete={() => {
                let item = getSelectedItem(selectionModel);
                let selected: string[] = [];
                if (item) {
                  selected.push(item.id);
                }
                setSelectionModel(selected);
                setShowDelete(true);
              }}
            />
            <BulkEditDialog
              open={openUploadBulk}
              onClose={handleCloseUploadBulk}
            />
            <ConfirmDialog
              open={showDelete}
              title="Confirm"
              text={
                "Are you sure you want to delete " +
                selectionModel.length +
                " inventory item(s)?"
              }
              onCancel={() => {
                setShowDelete(false);
                setSelectionModel([]);
              }}
              onConfirm={handleDeleteSelected}
            />
          </Paper>
        </div>
      </Grid>
    </Grid>
  );
};
