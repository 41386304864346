import { all, call, /* delay, */ fork, put, take/*, select */ } from "redux-saga/effects";
import {
  fetchLoginApi,
  loginWithTokenApi,
  logoutApi,
  resetPasswordApi,
  signupApi,
  updateUserApi,
  updateUserStatusApi,
} from "../../apiService";
import { User } from "../../interfaces";

import { FAIL, START, SUCCESS } from "../common";
import {
  FETCH_LOGIN,
  LOGIN_WITH_TOKEN,
  LOG_OUT,
  RESET_PASSWORD,
  SIGNUP,
  UPDATE_USER,
} from "./actions";

import { isSalesFilterValid } from "src/enums/sale.enum";

interface LoginResponse {
  token: string;
  user: User;
}

const FIREBASE_DUPLICATE_SIGNUP_ERROR = "auth/email-already-in-use";
const FIREBASE_USER_NOT_FOUND_ERROR = "auth/user-not-found";
function* login() {
  while (true) {
    const { payload } = yield take(FETCH_LOGIN + START);
    try {
      const data: LoginResponse = yield call(fetchLoginApi, payload.email, payload.password);
      yield put({ type: FETCH_LOGIN + SUCCESS, payload: data });
    } catch (error: any) {
      yield put({ type: FETCH_LOGIN + FAIL, payload: error.message });
    }
  }
}
function* tokenLogin() {
  while (true) {
    const { payload } = yield take(LOGIN_WITH_TOKEN + START);
    try {
      const data: LoginResponse = yield call(loginWithTokenApi, payload);
      yield put({ type: FETCH_LOGIN + SUCCESS, payload: data });
    } catch (error: any) {
      yield put({ type: FETCH_LOGIN + FAIL, payload: error.message });
    }
  }
}

function* signup() {
  while (true) {
    const { payload } = yield take(SIGNUP + START);
    try {
      const data: LoginResponse = yield call(signupApi, payload.email, payload.password);
      yield put({ type: SIGNUP + SUCCESS, payload: data });
    } catch (error: any) {
      let message: string = error.message;
      if (error.code === FIREBASE_DUPLICATE_SIGNUP_ERROR) {
        message = "The email address you entered is already registered. Log in or enter a different email address.";
      }
      yield put({ type: SIGNUP + FAIL, payload: message });
    }
  }
}

function* resetPassword() {
  while (true) {
    const { payload } = yield take(RESET_PASSWORD + START);
    try {
      yield call(resetPasswordApi, payload.email);
      yield call(updateUserStatusApi, payload.email);
      yield put({ type: RESET_PASSWORD + SUCCESS });
    } catch (error: any) {
      let message: string = error.message;
      if (error.code === FIREBASE_USER_NOT_FOUND_ERROR) {
        message = "The email address you entered is incorrect or the account does not exist";
      }
      yield put({ type: RESET_PASSWORD + FAIL, payload: message });
    }
  }
}

function* updateUser() {
  while (true) {
    const { payload } = yield take(UPDATE_USER + START);
    const updatedPayload: any = {
      pm_id: payload.pm_id || null,
      plan: payload?.plan || null,
      coupon: payload.coupon || null,
      platforms: payload.platforms || null,
      income_tax_rate: payload.income_tax_rate || null,
      customer_id: payload.customer_id || null,
      sales_filter:
        isSalesFilterValid(payload.sales_filter)
          ? payload.sales_filter
          : null,
    };

    try {
      yield call(updateUserApi, updatedPayload);
      yield put({ type: UPDATE_USER + SUCCESS });
    } catch (error: any) {
      const message = error?.response?.data?.message || error?.message;
      yield put({ type: UPDATE_USER + FAIL, payload: message });
    }
  }
}

function* logout() {
  while (true) {
    yield take(LOG_OUT);
    yield logoutApi();
  }
}

export function* appSagas() {
  yield all([
    fork(login),
    fork(tokenLogin),
    fork(signup),
    fork(resetPassword),
    fork(logout),
    fork(updateUser),
  ]);
}
