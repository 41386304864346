import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";

import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";

import {
  LicenseInfo,
  useGridApiRef,
  GridSelectionModel,
  type GridFilterModel,
  type GridSortModel,
} from "@material-ui/x-grid";
import XGrid from "src/components/ConfiguredXGrid";
import CSVFormattedXGrid from "src/components/CSVFormattedXGrid";
import { createTableToolbar } from "src/components/TableToolbar";

// import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";

import { EditDialog } from "./EditDialog";
import ConfirmDialog from "src/components/ConfirmDialog";
import InfoTooltip from "../../components/InfoTooltip";

import ConfirmReturnDetails from "src/pages/RootDialogs/ReviewReturnsDialog/ConfirmReturnDetails";

import { formatCurrency /*, parseError */ } from "../../utils";

import { clearUpdateMethod } from "../../store/common";
import {
  updateItem,
  updateBundledItems,
  deleteBundledItems,
  moveBundledItems,
  moveItem,
  recordReturn,
  setFilter,
} from "src/store/sale/actions";

import {
  visibleReturnsSelector,
  salesSelector,
  getSaleById,
  salesFilterSelector,
  visibleSalesSelector,
} from "src/store/sale/selector";

import { METHOD_TYPE } from "../../enums";
import { X_GRID_LICENSE_KEY } from "../../config";
import BulkEditDialog from "./BulkEditDialog";

import { valueGetterWithDefault } from "src/utils/valueGetter";
import { setActiveDialog } from "src/store/adminHtml/actions";
import {
  deleteReturn,
  reviewManySales,
  deleteManySales,
} from "src/apiService/modules/sales";
import { StatefulLoadableButton } from "src/components/LoadableButton";
import {
  userPlanSelector,
  userGetInventoryTypeSelector,
} from "src/store/system/selector";

import vendorRenderCell from "src/utils/vendorRenderCell";
import renderHeaderDescriptionLabel from "src/utils/renderHeaderLabel";

import withTooltipRendererCell from "src/utils/withTooltipRendererCell";

// vim-highlight-fix = }

LicenseInfo.setLicenseKey(X_GRID_LICENSE_KEY);

const SalesTableToolbar = createTableToolbar({
  options: { fileName: "My Reseller Genie - Sales" },
});
const ReturnsTableToolbar = createTableToolbar({
  options: { fileName: "My Reseller Genie - Returns" },
});

const tableColumnInfo = {
  location: "Where you have stored the item.",
  purchase_date: "The date that you purchased this item.",
  list_date: "The date that you listed this item for sale.",
  sale_date: "The date that you sold this item.",
  days_on_platform:
    "The number of days the item was listed before selling. In other words ‘Sale Date’ – ‘List Date’.",
  sale_price:
    "The total revenue collected from a customer. This includes the price of the item and additional money collected for shipping. This does not include sales tax.",
  purchase_price: "The amount you paid for the inventory.",
  shipping_cost:
    "The amount you paid to ship the sale. This amount will be included in the P&L and Management Dashboard. If your shipping is accounted for in the expense detail, don’t add the cost here as well – this will result in duplicate shipping expenses.",
  shipping_cost_analytics:
    'This includes the shipping transactions that tie to the order number for this sale. These are taken from the "Expense Detail" so that we can deliver you better analytics. This field is not factored into your P&L or Management Dashboard since the expenses in the "Expense Detail" are included in these numbers.',
  transaction_fees: "The amount the platform charged you to sell the item.",
  other_fees:
    'This includes the non-shipping transactions that tie to the order number for this sale. These are taken from the "Expense Detail" so that we can deliver you better analytics. This field is not factored into your P&L or Management Dashboard since the expenses in the "Expense Detail" are included in these numbers.',
  gross_profit:
    "Gross Profit = Sale Price - Purchase Price - Shipping Costs - Shipping Costs (From Expense Detail) - Transaction Fees - Other Costs (From Expense Detail).",
  sales_tax: "The sales tax paid by the buyer.",
  liable_to_pay:
    "If you aren’t responsible for filing sales tax, mark this field as ‘No’. If you are responsible for filing sales tax, mark this field as ‘Yes’.",
  notes: "Enter any other information that you need in here.",
};

// XXX: 2023-09-23: Match to Inventory feature was temporary made available to all plans
function NonUltimateTooltip({ children, ...props }: TooltipProps) {
  const plan = useSelector(userPlanSelector);
  if (plan === "ultimate") return children;
  return <Tooltip {...props}>{children}</Tooltip>;
}

export function ConfirmReturnDetailsDialog({ selectedUnreviewedReturnId, onClose }) {
  const unreviewedReturn = useSelector((s: any) =>
    getSaleById(s, selectedUnreviewedReturnId)
  );
  return (
    <Dialog
      open={!!unreviewedReturn}
      onClose={onClose}
      scroll="body"
      fullWidth
      maxWidth="sm"
    >
      {!!unreviewedReturn && (
        <ConfirmReturnDetails
          onClose={onClose}
          unreviewedReturn={unreviewedReturn}
          onGoToSale={onClose}
          onSave={onClose}
        />
      )}
    </Dialog>
  );
}

export const renderHeaderLabel = (params) => {
  const tooltip = tableColumnInfo[params.field];
  return (
    <div className="MuiDataGrid-columnHeaderTitle">
      {params.colDef.headerName}
      {!!tooltip && <InfoTooltip size="small" text={tooltip} />}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: `1px solid #f0f0f0`,
      padding: "0px 10px",
      fontSize: "12px",
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid #f0f0f0`,
    },
    "& .MuiDataGrid-cell": {
      color: `rgba(0,0,0,.85)`,
    },
    "& .MuiDataGrid-iconSeparator": {
      opacity: 0,
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#fafafa",
      borderTop: "1px solid #f0f0f0",
      fontSize: "12px",
    },
    "& .MuiDataGrid-colCell": {
      borderRight: `1px solid #f0f0f0`,
      padding: "0px 10px",
    },
  },
  highlightedRow: {
    background: "#FFFAA0",
  },
});

let Columns = [
  {
    field: "id",
    hide: true,
  },
  {
    field: "transaction_id",
    headerName: "Platform ID",
    width: 150,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "item_title",
    headerName: "Item Title",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "department",
    headerName: "Department",
    width: 150,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "category",
    headerName: "Category",
    width: 200,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "sub_category",
    headerName: "Sub Category",
    width: 200,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "vendor",
    headerName: "Vendor",
    width: 200,
    renderCell: vendorRenderCell,
    renderHeader: renderHeaderDescriptionLabel,
    description: "The vendor is where you purchased the item (ex. Goodwill).",
    valueGetter: (params) => params.row.vendorName || params.value || "",
  },
  {
    field: "brand",
    headerName: "Brand",
    width: 150,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "location",
    headerName: "Location",
    width: 200,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "sku",
    headerName: "SKU",
    width: 150,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "purchase_date",
    headerName: "Purchase Date",
    type: "date",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "list_date",
    headerName: "List Date",
    type: "date",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "sale_date",
    headerName: "Sale Date",
    type: "date",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "days_on_platform",
    headerName: "Days On Platform",
    type: "number",
    width: 220,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "sale_price",
    headerName: "Sale Price",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "purchase_price",
    headerName: "Purchase Price",
    type: "number",
    width: 220,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "shipping_cost",
    headerName: "Shipping Costs",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "shipping_cost_analytics",
    headerName: "Shipping Costs (From Expense Detail)",
    type: "number",
    width: 285,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "transaction_fees",
    headerName: "Transaction Fees",
    type: "number",
    width: 220,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "other_fees",
    headerName: "Other Costs (From Expense Detail)",
    type: "number",
    width: 285,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "gross_profit",
    headerName: "Gross profit",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "sale_state",
    headerName: "Sale State",
    width: 200,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "sales_tax",
    headerName: "Sales Tax",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "liable_to_pay",
    headerName: "Liable To Pay",
    width: 200,
    type: "boolean",
    renderHeader: renderHeaderLabel,
  },
  {
    field: "platforms_listed",
    headerName: "Platforms Listed",
    width: 300,
    renderHeader: renderHeaderLabel,
    valueFormatter: ({ value }) => {
      if (value && value.length) {
        return `${value.join(", ")}`;
      }
      return "";
    },
  },
  {
    field: "sale_platform",
    headerName: "Sale Platform",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "notes",
    headerName: "Notes",
    width: 200,
    renderHeader: renderHeaderLabel,
    valueGetter: valueGetterWithDefault(""),
  },
  {
    field: "status",
    headerName: "Status",
    width: 125,
    valueGetter: (params) => {
      const value = params.row.in_process;
      if (value === true) return "In Process";
      return "Complete";
    },
  },
];

const ReturnColumns = [
  {
    field: "id",
    sortable: false,
    filterable: false,
    hide: true,
  },
  {
    field: "transaction_id",
    headerName: "Platform ID",
    width: 200,
    valueGetter: valueGetterWithDefault(""),
  },
  ...Columns.slice(2, 12),
  {
    field: "return_date",
    headerName: "Return Date",
    type: "date",
    width: 200,
    renderHeader: renderHeaderLabel,
  },
  ...Columns.slice(12, 17),
  {
    field: "extra_shipping_cost",
    headerName: "Return Shipping Cost",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  {
    field: "extra_transaction_fees",
    headerName: "Return Transaction Fees",
    type: "number",
    width: 200,
    valueFormatter: formatCurrency,
    renderHeader: renderHeaderLabel,
  },
  ...Columns.slice(17),
];

const getPendingTitle = () =>
  "Sales highlighted in yellow have not been completed yet.";
const pedingChecker = ({ row }) => !!row.in_process;
Columns = Columns.map((c) =>
  withTooltipRendererCell(c, getPendingTitle, pedingChecker)
);
const ColumnsCashIgnored = [
  "purchase_date",
  "purchase_price",
  "platforms_listed",
];
const ColumnsCash = Columns.filter(
  ({ field }) => !ColumnsCashIgnored.includes(field)
);
const ReturnColumnsCash = ReturnColumns.filter(
  ({ field }) => !ColumnsCashIgnored.includes(field)
);

export function TableContent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { method, loading, error } = useSelector(salesSelector);
  const filterModel = useSelector(salesFilterSelector);
  const initialReturns = useSelector(visibleReturnsSelector);
  const inventoryTypeIsCash =
    useSelector(userGetInventoryTypeSelector) === "cash";
  // XXX: 2023-09-23: Match to Inventory feature was temporary made available to all plans
  // const plan = useSelector(userPlanSelector);
  const sales = useSelector(visibleSalesSelector);
  const [salesSortModel, setSalesSortModel] = useState<GridSortModel>([
    { field: "sale_date", sort: "desc" },
  ]);
  const [returnsSortModel, setReturnsSortModel] = useState<GridSortModel>([
    { field: "return_date", sort: "desc" },
  ]);

  const [editItemId, setEditItemId] = useState<string>();
  const [showDelete, setShowDelete] = useState(false);
  const [selectedUnreviewedReturnId, setSelectedUnreviewedReturnId] =
    useState<string>();
  const [showDeleteBundle, setShowDeleteBundle] = useState<any[] | null>(null);
  const [showDeleteReturn, setShowDeleteReturn] = useState<any>(null);
  const returnItems = initialReturns || [];
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [returnSelectionModel, setReturnSelectionModel] =
    useState<GridSelectionModel>([]);

  const [openUploadBulk, setOpenUploadBulk] = useState(false);

  const gridRef = useGridApiRef();
  const returnGridRef = useGridApiRef();

  useEffect(() => {
    const isDialogActions =
      method === METHOD_TYPE.UPDATE ||
      method === METHOD_TYPE.MOVE ||
      method === METHOD_TYPE.DELETE;
    if (!error && !loading && isDialogActions) {
      setEditItemId(undefined);
      dispatch(clearUpdateMethod());
    }
  }, [loading]);

  const handleCloseUploadBulk = () => {
    setOpenUploadBulk(false);
  };

  const handleOpenUploadBulk = () => {
    setOpenUploadBulk(true);
  };

  const handleCloseEdit = () => {
    if (
      (method === METHOD_TYPE.UPDATE || method === METHOD_TYPE.DELETE) &&
      loading
    )
      return;
    setEditItemId(undefined);
  };

  const getSelectedItem = (id) => {
    if (!id) return undefined;
    return [...sales, ...returnItems].find((item) => item.id === id);
  };

  const gotoEditReturn = (item, unreviewed) => {
    if (unreviewed) setSelectedUnreviewedReturnId(item.return_id);
    else setEditItemId(item.return_id);
  };

  const handleSaveItem = (item: any) => {
    dispatch(updateItem(item));
  };

  const handleSaveBundle = (items: any[]) => {
    dispatch(updateBundledItems(items));
  };

  const handleSelectAndEdit = (e: any, type) => {
    if (type === "sales") {
      setSelectionModel([e.row.id]);
    } else {
      setReturnSelectionModel([e.row.id]);
    }
    setEditItemId(e.row.id);
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    dispatch(setFilter(model));
  };

  const handleDeleteBundle = () => {
    dispatch(deleteBundledItems(showDeleteBundle));
    setShowDeleteBundle(null);
    setEditItemId(undefined);
  };

  const handleMoveItem = (item: any) => {
    dispatch(moveItem(item));
  };

  const handleMoveBundle = (items: any) => {
    dispatch(moveBundledItems(items));
  };

  const handleReturn = (returnRecord: any, sale: any, inventory: any) =>
    dispatch(recordReturn({ returnRecord, sale, inventory }));

  const closeDeleteConfirm = () => {
    setShowDelete(false);
    setShowDeleteBundle(null);
    setShowDeleteReturn(null);
  };

  const handleSaleDeleteSelected = () => {
    setShowDelete(false);
    if (selectionModel.length > 0) {
      deleteManySales(selectionModel.map(String));
      setSelectionModel([]);
      setEditItemId(undefined);
    }
  };

  const handleReturnDeleteSelected = () => {
    setShowDeleteReturn(false);
    if (returnSelectionModel.length > 0) {
      returnSelectionModel.forEach((id) => {
        deleteReturn(`${id}`).then((result) => {
          console.log(result);
        });
      });
      setReturnSelectionModel([]);
      setEditItemId(undefined);
      setShowDelete(false);
    }
  };

  let confirmDeleteMessage =
    "Are you sure you want to delete " + selectionModel.length + " sale(s)?";
  if (showDeleteBundle) {
    confirmDeleteMessage = `Are you sure you want to delete ${
      showDeleteBundle?.length || 0
    } bundled sales?`;
  } else if (showDeleteReturn) {
    confirmDeleteMessage =
      "Are you sure you want to delete " +
      returnSelectionModel.length +
      " return(s)?";
  }

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <div>
          <Paper className="p-5 h-auto mt-4">
            <div className="flex justify-between flex-wrap mb-3">
              <h2 className="text-left text-blue mb-0">Sales Detail</h2>
              <div className={"flex items-center"}>
                <Button
                  variant="contained"
                  color="primary"
                  className="h-9 ml-3 mt-1"
                  onClick={handleOpenUploadBulk}
                >
                  Bulk Edit Sales
                </Button>
                {/* XXX: 2023-09-23: Match to Inventory feature was temporary made available to all plans */}
                {/*{  plan === "ultimate" && ( */}
                <NonUltimateTooltip title="This feature is normally only available to Ultimate users. It's temporarily available for Basic and Ultimate users to help correct a bug that was present between 9/8/23-9/21/23.">
                  <span>
                    <StatefulLoadableButton
                      variant="contained"
                      color="primary"
                      className="h-9 ml-3 mt-1"
                      disabled={loading || selectionModel.length === 0}
                      onClick={async () => {
                        if (!selectionModel.length) return;
                        await reviewManySales(selectionModel.map(String), true);
                        setSelectionModel([]);
                        dispatch(setActiveDialog("review_sales"));
                      }}
                    >
                      Match to Inventory
                    </StatefulLoadableButton>
                  </span>
                </NonUltimateTooltip>
                {/* ) } */}
                <Button
                  variant="contained"
                  color="secondary"
                  className="h-9 ml-3 mt-1"
                  disabled={loading || selectionModel.length === 0}
                  onClick={() => {
                    setShowDelete(true);
                  }}
                  style={{ marginRight: 10 }}
                >
                  Delete Selected
                </Button>
              </div>
            </div>
            <XGrid
              checkboxSelection
              tableName="sales"
              Component={CSVFormattedXGrid}
              className="h-table"
              apiRef={gridRef}
              rows={sales}
              columns={inventoryTypeIsCash ? ColumnsCash : Columns}
              headerHeight={35}
              rowHeight={35}
              // pageSize={salePageSize}
              // rowsPerPageOptions={[10, 25, 50, 100]}
              // pagination
              loading={loading && method === METHOD_TYPE.LIST}
              components={{
                Toolbar: SalesTableToolbar,
              }}
              onCellDoubleClick={(event) => handleSelectAndEdit(event, "sales")}
              onFilterModelChange={handleFilterModelChange}
              filterModel={filterModel}
              getRowClassName={({ row }) => {
                if (row.in_process) return classes.highlightedRow;
                return "";
              }}
              filterMode="server"
              selectionModel={selectionModel}
              onSelectionModelChange={(selectionModel: GridSelectionModel) => {
                setSelectionModel(selectionModel);
              }}
              sortModel={salesSortModel}
              onSortModelChange={(model) => {
                setSalesSortModel(model);
              }}
            />
          </Paper>
          <Paper className="p-5 h-auto mt-4">
            <div className="flex justify-between flex-wrap mb-3">
              <h2 className="text-left text-blue mb-0">
                Returns / Refunds / Cancellations
              </h2>
              <div className={"flex items-center"}>
                <Button
                  variant="contained"
                  color="secondary"
                  className="h-9 ml-3 mt-1"
                  disabled={loading || returnSelectionModel.length === 0}
                  onClick={() => {
                    setShowDeleteReturn(true);
                    setShowDelete(true);
                  }}
                  style={{ marginRight: 10 }}
                >
                  Delete Selected
                </Button>
              </div>
            </div>

            <XGrid
              checkboxSelection
              checkboxSelectionVisibleOnly
              tableName="returns"
              Component={CSVFormattedXGrid}
              className="h-table"
              apiRef={returnGridRef || undefined}
              rows={returnItems}
              columns={inventoryTypeIsCash ? ReturnColumnsCash : ReturnColumns}
              headerHeight={35}
              rowHeight={35}
              // pageSize={returnPageSize}
              // rowsPerPageOptions={[10, 25, 50, 100]}
              // pagination
              loading={loading && method === METHOD_TYPE.LIST}
              components={{
                Toolbar: ReturnsTableToolbar,
              }}
              selectionModel={returnSelectionModel}
              onSelectionModelChange={(selectionModel: GridSelectionModel) => {
                setReturnSelectionModel(selectionModel);
              }}
              onCellDoubleClick={(event) =>
                handleSelectAndEdit(event, "returns")
              }
              onFilterModelChange={handleFilterModelChange}
              filterModel={filterModel}
              sortModel={returnsSortModel}
              onSortModelChange={(model) => {
                setReturnsSortModel(model);
              }}
            />
          </Paper>
          <EditDialog
            data={getSelectedItem(editItemId)}
            open={!!editItemId}
            loading={loading}
            onClose={handleCloseEdit}
            onSave={handleSaveItem}
            onSaveBundle={handleSaveBundle}
            onMove={handleMoveItem}
            onMoveBundle={handleMoveBundle}
            onReturn={handleReturn}
            onDelete={() => {
              const item = getSelectedItem(editItemId);
              const selected: string[] = [];
              if (item) selected.push(item.id);
              setSelectionModel(selected);
              setShowDelete(true);
            }}
            onDeleteBundle={(items) => {
              setShowDeleteBundle(items);
            }}
            onDeleteReturn={(payload) => {
              const item = getSelectedItem(editItemId);
              const selected: string[] = [];
              if (item) selected.push(item.id);
              setReturnSelectionModel(selected);
              setShowDeleteReturn(payload);
            }}
            editReturn={gotoEditReturn}
          />
          <ConfirmReturnDetailsDialog
            selectedUnreviewedReturnId={selectedUnreviewedReturnId}
            onClose={() => setSelectedUnreviewedReturnId(undefined)}
          />
          <BulkEditDialog
            open={openUploadBulk}
            onClose={handleCloseUploadBulk}
          />
          <ConfirmDialog
            open={showDelete || !!showDeleteBundle || !!showDeleteReturn}
            title="Confirm"
            text={confirmDeleteMessage}
            onCancel={closeDeleteConfirm}
            onConfirm={() => {
              if (showDeleteBundle) {
                return handleDeleteBundle();
              }
              if (showDeleteReturn) {
                return handleReturnDeleteSelected();
              }
              return handleSaleDeleteSelected();
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
}
