import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function StripeDialog({ open, handleClose, link }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="body"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="goto-stripe relative">Leaving page</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are leaving this page for the Stripe customer portal to securely
          manage your subscription. Proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions className="px-7 py-5">
        <Button onClick={handleClose} color="primary" variant="contained">
          Cancel
        </Button>
        <Button
          component="a"
          color="secondary"
          variant="contained"
          disabled={!link}
          href={link}
        >
          {!link && <CircularProgress size={15} className="mr-3" />}
          {!link ? "Connecting to Stripe..." : "Go to Stripe"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StripeDialog;
