import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LogInPage from "../pages/LogInPage";
import PasswordResetPage from "../pages/PasswordResetPage";
import SignupPage from "../pages/SignupPage";
import AuthCallbackPage from "../pages/AuthCallbackPage"; // TODO: deprecate
import EBayOAuthCallbackPage from "src/pages/EBayOAuthCallbackPage";
import AmazonOAuthCallbackPage from "src/pages/AmazonOAuthCallbackPage";
import PrivateRouter from "./PrivateRouter";
import SupportRouter from "./SupportRouter";

import {
  authorizingSelector,
  isLoggedInSelector,
} from "../store/system/selector";
import { AppLoader } from "../components";
import PlaidAuthCallbackPage from "../pages/PlaidAuthCallbackPage";
import { SafeZendesk as Zendesk } from "src/components/Zendesk";
import EtsyAuthCallbackPage from "../pages/EtsyAuthCallbackPage/EtsyAuthCallbackPage";

function RouterWrapper() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/support" component={SupportRouter} />
          <Route component={Router} />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export function Router() {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const authorizing = useSelector(authorizingSelector);

  if (authorizing) return <AppLoader />;

  return (
    <>
      <Switch>
        <Route exact path="/login" component={LogInPage} />
        <Route exact path="/signup" component={SignupPage} />
        <Route exact path="/forgotpassword" component={PasswordResetPage} />
        <Route exact path="/auth/ebay/callback" component={AuthCallbackPage} />
        <Route
          exact
          path="/auth/etsy/callback"
          component={EtsyAuthCallbackPage}
        />
        <Route
          exact
          path="/oauth/ebay/callback"
          component={EBayOAuthCallbackPage}
        />
        <Route
          exact
          path="/auth/plaid/callback"
          component={PlaidAuthCallbackPage}
        />
        <Route
          exact
          path="/oauth/amazon/callback"
          component={AmazonOAuthCallbackPage}
        />
        {!authorizing && isLoggedIn && <PrivateRouter />}
        {!authorizing && !isLoggedIn && <Redirect to="/login" />}
      </Switch>
      <Zendesk isLoggedIn={isLoggedIn} />
    </>
  );
}

export default RouterWrapper;
