import { createAction } from "redux-actions";
import { User } from "../../interfaces";
import { START } from "../common";

import { TableConfiguration } from "src/interfaces/systemState.interface";
import { tablesConfigurationSelector } from "src/store/system/selector";
import { updateTableConfiguration } from "src/apiService/modules/tablesConfiguration";
import { updelsertAnalyticGrouping, UpdelsertTransactionRuleParams } from "src/apiService/modules/analyticsGroupings";

export const UPDATE_SESSION = "UPDATE_SESSION";
export const FETCH_LOGIN = "FETCH_LOGIN";
export const LOGIN_WITH_TOKEN = "LOGIN_WITH_TOKEN";
export const SIGNUP = "SIGNUP";
export const UPDATE_USER = "UPDATE_USER";
export const SET_USER = "SET_USER";
export const LOG_OUT = "LOG_OUT";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CLEAR_SIGNUP_STATUS = "CLEAR_SIGNUP_STATUS";
export const CLEAR_SUBSCRIPTION_RESPONSE = "CLEAR_SUBSCRIPTION_RESPONSE";
export const UPDATE_TABLE_COLUMN = "UPDATE_TABLE_COLUMN";
export const UPDATE_ANALYTICS_GROUPINGS = "UPDATE_ANALYTICS_GROUPINGS";

interface FetchLoginPayload {
  email: string;
  password: string;
}

interface FetchLoginSuccessPayload {
  token: string;
  user: User;
}

interface UpdateUserPayload {
  pm_id?: string;
  plan?: "basic" | "premium" | "ultimate";
  coupon?: string;
  customer_id?: string | number;
  platforms?: string[];
  income_tax_rate?: number;
  sales_filter?: any;
}

interface ResetPasswordPayload {
  email: string;
}

export type SystemPayload =
  | FetchLoginPayload
  | FetchLoginSuccessPayload
  | UpdateUserPayload
  | ResetPasswordPayload;

export interface UpdateTableColumnWidth {
  column: string;
  width: number;
}

export interface UpdateTableColumnOrder {
  column: string;
  target: number;
  old: number;
}

export interface UpdateTableColumnVisibility {
  column: string;
  visibility: boolean;
}

export interface TableConfigurationPayload {
  table: string;
  config: TableConfiguration;
}

export const fetchLogin = createAction<SystemPayload>(FETCH_LOGIN + START);
export const loginWithToken = createAction<string>(LOGIN_WITH_TOKEN + START);
export const signup = createAction<SystemPayload>(SIGNUP + START);
export const updateUser = createAction<SystemPayload>(UPDATE_USER + START);
export const setUser = createAction<User>(SET_USER);
export const logOut = createAction(LOG_OUT);
export const clearSignupStatus = createAction(CLEAR_SIGNUP_STATUS);
export const resetPassword = createAction(RESET_PASSWORD + START);
export const clearSubscriptionResponse = createAction(
  CLEAR_SUBSCRIPTION_RESPONSE
);
export const updateTableColumnAction =
  createAction<TableConfigurationPayload>(UPDATE_TABLE_COLUMN);

export function updateTableColumn(
  tableName: string,
  action: "width",
  data: UpdateTableColumnWidth
);
export function updateTableColumn(
  tableName: string,
  action: "order",
  data: UpdateTableColumnOrder
);
export function updateTableColumn(
  tableName: string,
  action: "visibility",
  data: UpdateTableColumnVisibility
);
export function updateTableColumn(tableName, action, data) {
  return (dispatch, getState) => {
    const tableConfiguration = tablesConfigurationSelector(getState());
    const config = tableConfiguration[tableName];

    if (action === "width") {
      data = data as UpdateTableColumnWidth;
      const tableConfiguration = {
        ...config,
        width: {
          ...config?.width,
          [data.column]: data.width,
        },
      };

      dispatch(
        updateTableColumnAction({
          table: tableName,
          config: tableConfiguration,
        })
      );
      updateTableConfiguration(tableName, tableConfiguration);
    } else if (action === "order") {
      data = data as UpdateTableColumnOrder;
      if (data.target === data.old) return;

      const order = { ...config?.order };
      order[data.column] = data.old;

      const keys = Object.keys(order);
      const existingIndexes = new Set();
      for (const key of keys) {
        while (existingIndexes.has(order[key])) {
          order[key] += 1;
        }
        existingIndexes.add(order[key]);
      }

      if (data.target < data.old) {
        for (let i = data.old - 1; i >= data.target; i--) {
          for (const key of keys) {
            if (order[key] === i) order[key] += 1;
          }
        }
      } else {
        for (let i = data.old + 1; i <= data.target; i++) {
          for (const key of keys) {
            if (order[key] === i) order[key] -= 1;
          }
        }
      }
      order[data.column] = data.target;
      const tableConfiguration = {
        ...config,
        order,
      };

      dispatch(
        updateTableColumnAction({
          table: tableName,
          config: tableConfiguration,
        })
      );
      updateTableConfiguration(tableName, tableConfiguration);
    } else if (action === "visibility") {
      data = data as UpdateTableColumnVisibility;
      const tableConfiguration = {
        ...config,
        hide: {
          ...config?.hide,
          [data.column]: !data.visibility,
        },
      };

      dispatch(
        updateTableColumnAction({
          table: tableName,
          config: tableConfiguration,
        })
      );
      updateTableConfiguration(tableName, tableConfiguration);
    }
  };
}
export const updateAnalyticsGroupingsAction =
  createAction<UpdelsertTransactionRuleParams>(UPDATE_ANALYTICS_GROUPINGS);

export function updateAnalyticsGroupings(params: UpdelsertTransactionRuleParams) {
  return (dispatch) => {
    dispatch(updateAnalyticsGroupingsAction(params));
    return updelsertAnalyticGrouping(params);
  };
}
