import produce from "immer";
import type {
  SupportState,
  SupportStateUserDataSuccess,
} from "src/interfaces/supportState.interface";

import {
  SupportActionTypes,
  SET_SUPPORT_LOGIN_TOKEN,
  SET_SUPPORT_USER_TOKEN,
  SET_SUPPORT_USER_DATA,
  UPDATE_SUPPORT_USER_INTEGRATION,
  SET_SUPPORT_EBAY_LIMITS,
} from "./actions";

const initialState: SupportState = {
  token: "",
  userTokens: {},
  userData: {},
  eBayLimits: {},
};

export function supportReducer(
  state = initialState,
  action: SupportActionTypes
): SupportState {
  switch (action.type) {
    case SET_SUPPORT_LOGIN_TOKEN:
      return {
        ...state,
        token: action.token,
      };

    case SET_SUPPORT_USER_TOKEN:
      return {
        ...state,
        userTokens: {
          ...state.userTokens,
          [action.payload.username]: action.payload,
        },
      };

    case SET_SUPPORT_USER_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          [action.payload.username]: action.payload,
        },
      };

    case UPDATE_SUPPORT_USER_INTEGRATION:
      return {
        ...state,
        userData: produce(state.userData, (userData) => {
          for (const u of Object.values(userData)) {
            const user = u as SupportStateUserDataSuccess;
            if (!user.integrations) continue;
            for (const integration of user.integrations) {
              if (integration.id === action.payload.id) {
                Object.assign(integration, action.payload);
                return;
              }
            }
          }
        }),
      };

    case SET_SUPPORT_EBAY_LIMITS:
      return {
        ...state,
        eBayLimits: action.payload,
      };

    default:
      return state;
  }
}
