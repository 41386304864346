import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

import MonthYearField, {
  CurrentMonthYear,
} from "src/pages/GetStarted/ImportDialog/MonthYearField";
import LoadableButton from "src/components/LoadableButton";

import type MonthYear from "src/interfaces/monthYear.interface";

export type DataType = "Inventory" | "Sales";
export interface IntegrationImportDialogProps {
  open: boolean;
  onImport: (
    dataType: DataType,
    dates?: { start: MonthYear; end: MonthYear }
  ) => void;
  onClose: () => void;
  loading: boolean;
}

function IntegrationImportDialogContent({
  onImport,
  loading,
}: Omit<IntegrationImportDialogProps, "open" | "onClose">) {
  const [date, setDate] = useState({
    start: CurrentMonthYear,
    end: CurrentMonthYear,
  });
  const [dataType, setDataType] = useState<DataType>("Inventory");
  const startIsGreaterThanNow =
    dataType === "Sales" &&
    (CurrentMonthYear.year < date.start.year ||
      (CurrentMonthYear.year === date.start.year &&
        CurrentMonthYear.month < date.start.month));
  const startIsGreaterThanEnd =
    dataType === "Sales" &&
    (date.end.year < date.start.year ||
      (date.end.year === date.start.year && date.end.month < date.start.month));

  return (
    <>
      <DialogTitle>Import</DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          Dispatch a direct import job
        </Typography>
        <Select
          onChange={(e) => {
            const value = e.target.value as DataType;
            setDataType(value);
          }}
          variant="outlined"
          value={dataType}
          disabled={loading}
        >
          <MenuItem value="Inventory">Inventory</MenuItem>
          <MenuItem value="Sales">Sales</MenuItem>
        </Select>
        {dataType === "Sales" && (
          <Box mt={2}>
            <MonthYearField
              disabled={loading}
              value={date.start}
              onChange={(f) => {
                setDate((d) => ({
                  ...d,
                  start: f(d.start),
                }));
              }}
              error={
                startIsGreaterThanNow
                  ? "The starting date can’t be in the future."
                  : undefined
              }
            >
              Enter the starting date for the sales you want to import.
            </MonthYearField>
            <MonthYearField
              disabled={loading}
              value={date.end}
              onChange={(f) => {
                setDate((d) => ({
                  ...d,
                  end: f(d.end),
                }));
              }}
              error={
                startIsGreaterThanEnd
                  ? "The ending date must be after the starting date."
                  : undefined
              }
            >
              Enter the ending date for the sales you want to import.
            </MonthYearField>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <LoadableButton
          loading={loading}
          disabled={startIsGreaterThanEnd || startIsGreaterThanNow || loading}
          color="primary"
          variant="contained"
          onClick={() => {
            if (dataType === "Sales") onImport(dataType, date);
            else onImport(dataType);
          }}
        >
          Import {dataType}
        </LoadableButton>
      </DialogActions>
    </>
  );
}

function IntegrationImportDialog({
  open,
  onClose,
  onImport,
  loading,
}: IntegrationImportDialogProps) {
  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="sm">
      <IntegrationImportDialogContent onImport={onImport} loading={loading} />
    </Dialog>
  );
}

export default IntegrationImportDialog;
