import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import capitalize from "lodash/capitalize";
import { toast } from "react-toastify";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import LoadableButton from "src/components/LoadableButton";

import {
  checkCouponApi,
  subscriptionApi,
  getFreeTrialEligibility,
} from "src/apiService/modules/stripe";
import { clearSubscriptionResponse, logOut } from "src/store/system/actions";
import {
  paymentMethodSelector,
  userTrialEndDateSelector,
} from "src/store/system/selector";
import { colors } from "src/theme";
import TrialEndPrompt, {
  TrialEndPromptContent,
} from "src/pages/RootDialogs/TrialEndPrompt";

const COUPON_ERROR = "Coupon code not found.";

const useStyles = makeStyles((theme) => ({
  nameField: {
    width: "100%",
    margin: 0,
  },
  couponField: {
    width: "100%",
    marginTop: 0,
  },
  applyButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(-1),
  },
  applyButtonWrapperWithError: {
    marginTop: theme.spacing(-4),
  },
  flex: {
    display: "flex",
  },
  qty: {
    marginTop: theme.spacing(1.5),
  },
  cardElementContainer: {
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
    "&.StripeElement--focus": {
      borderColor: "rgb(68, 112, 147)",
      borderWidth: 2,
      padding: "9.5px 13px",
    },
    "&.StripeElement--invalid": {
      borderColor: "#f44336",
    },
    borderRadius: 8,
    width: "100%",
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "10.5px 14px",
  },
}));

const CardElementStyle = {
  base: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "16px",
    letterSpacing: "0.15008px",
  },
};

enum PLANS {
  BASIC = "basic",
  PREMIUM = "premium",
  ULTIMATE = "ultimate",
}

const plans = [PLANS.BASIC, PLANS.PREMIUM, PLANS.ULTIMATE];
const planPrices = {
  [PLANS.BASIC]: 9.99,
  [PLANS.PREMIUM]: 14.99,
  [PLANS.ULTIMATE]: 19.99,
};
const EBayIntegrationPrice = 5;

function getPrice(
  plan: PLANS.BASIC | PLANS.PREMIUM | PLANS.ULTIMATE,
  ebayIntegrationsQuantity: number
): number {
  if (plan !== PLANS.ULTIMATE) return planPrices[plan];
  return (
    planPrices[plan] +
    (ebayIntegrationsQuantity > 1
      ? (ebayIntegrationsQuantity - 1) * EBayIntegrationPrice
      : 0)
  );
}

function CheckFreeTrialEligibilityWrapper({ children }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let cancel = false;
    (async () => {
      setLoading(true);
      const result = await getFreeTrialEligibility();
      if (cancel || result.eligible) return;
      setLoading(false);
    })();
    return () => {
      cancel = true;
    };
  }, []);

  if (loading)
    return (
      <Box
        width="100%"
        height="200px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );

  return children;
}

function EnterPaymentMethodDialogContent({ onClose }: { onClose: () => void }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [selectedPlan, setPlan] = useState(PLANS.BASIC);
  const [coupon, setCoupon] = useState("");
  const [name, setName] = useState<{
    first: string;
    errorFirst?: string;
    last: string;
    errorLast?: string;
  }>({
    first: "",
    last: "",
  });
  const [checkingCoupon, setCheckingCoupon] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [flatDiscount, setFlatDiscount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [couponError, setCouponError] = useState("");
  const [ebayIntegrationsQuantity, setEbayIntegrationsQuantity] = useState(1);

  useEffect(() => {
    if (selectedPlan === PLANS.ULTIMATE) setEbayIntegrationsQuantity(1);
  }, [selectedPlan]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!name.first || !name.last) {
      setName((n) => {
        const name = { ...n };
        if (!n.first) name.errorFirst = "Required";
        if (!n.last) name.errorLast = "Required";
        return name;
      });

      return;
    }

    dispatch(clearSubscriptionResponse());

    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) return;

    setLoading(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      card: cardElement,
      type: "card",
    });

    if (error || !paymentMethod) {
      toast.error(error && error.message);
      console.error(error);
    } else {
      try {
        const response = await subscriptionApi({
          pm_id: paymentMethod.id,
          plan: selectedPlan,
          coupon,
          firstName: name.first,
          lastName: name.last,
          ebayIntegrationsQuantity:
            selectedPlan !== PLANS.ULTIMATE ? 1 : ebayIntegrationsQuantity,
        });

        if (response.success) {
          toast.success("Successfully updated!");
          onClose();
        } else {
          toast.error(response.message || "Something went wrong!");
        }
      } catch (error) {
        const message =
          (error as any)?.response?.data?.message || (error as any)?.message;
        if (message === COUPON_ERROR) {
          setCouponError(message);
        } else {
          toast.error(message);
        }
      }
    }

    setLoading(false);
  };

  const checkCoupon = async (code) => {
    if (!code) return;

    setCheckingCoupon(true);
    setDiscount(0);
    setCouponError("");

    try {
      const result = await checkCouponApi(code);
      setCheckingCoupon(false);
      const percentOff = result?.data?.coupon?.percent_off;
      const amountOff = result?.data?.coupon?.amount_off;
      if (percentOff) {
        setDiscount(percentOff);
        setFlatDiscount(0);
      } else if (amountOff) {
        setFlatDiscount(amountOff);
        setDiscount(0);
      } else {
        setCouponError("Invalid coupon");
        setDiscount(0);
      }
    } catch (e) {
      console.error(e);
      setCouponError("Error checking coupon");
    }
  };

  const handleLogout = () => dispatch(logOut());

  let price = getPrice(selectedPlan, ebayIntegrationsQuantity);
  if (discount) {
    price = Math.round(planPrices[selectedPlan] * (100 - discount)) / 100;
  } else if (flatDiscount) {
    price = Math.max(price - flatDiscount / 100, 0);
  }
  const displayPrice = price.toFixed(2);

  let couponIcon: JSX.Element | undefined = undefined;
  if (discount || flatDiscount) {
    couponIcon = (
      <InputAdornment position="end">
        <CheckCircleIcon style={{ color: colors.lightGreenColor }} />
      </InputAdornment>
    );
  } else if (checkingCoupon) {
    couponIcon = (
      <InputAdornment position="end">
        <CircularProgress size={18} />
      </InputAdornment>
    );
  }

  return (
    <>
      <DialogTitle>Confirm Subscription</DialogTitle>
      <DialogContent>
        <DialogContentText className="relative" tabIndex={-1} component="div">
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  className={classes.nameField}
                  variant="outlined"
                  label="First Name"
                  margin="dense"
                  value={name.first}
                  onChange={(e) => {
                    const first = e.target.value;
                    setName(({ errorFirst, ...n }) => {
                      if (first) return { ...n, first };
                      else return { ...n, errorFirst, first: "" };
                    });
                  }}
                  error={!!name.errorFirst}
                  helperText={name.errorFirst || undefined}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.nameField}
                  variant="outlined"
                  label="Last Name"
                  margin="dense"
                  value={name.last}
                  onChange={(e) => {
                    const last = e.target.value;
                    setName(({ errorLast, ...n }) => {
                      if (last) return { ...n, last };
                      else return { ...n, errorLast, last: "" };
                    });
                  }}
                  error={!!name.errorLast}
                  helperText={name.errorLast || undefined}
                />
              </Grid>
              <Grid item xs={12}>
                <CardElement
                  className={classes.cardElementContainer}
                  options={{
                    style: CardElementStyle,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl variant="outlined" className="w-full">
                  <TextField
                    className={classes.couponField}
                    variant="outlined"
                    label="Coupon code"
                    error={!!couponError}
                    helperText={couponError || undefined}
                    value={coupon}
                    onChange={(e) => {
                      setCoupon(e.target.value);
                      if (!e.target.value) setCouponError("");
                    }}
                    onKeyDown={(ev) => {
                      if (ev.key === "Enter" || ev.keyCode === 13) {
                        ev.preventDefault();
                        checkCoupon(coupon);
                      }
                    }}
                    margin="dense"
                    disabled={loading}
                    InputProps={{ endAdornment: couponIcon }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                className={clsx(
                  classes.applyButtonWrapper,
                  couponError && classes.applyButtonWrapperWithError
                )}
              >
                <LoadableButton
                  variant="contained"
                  loading={checkingCoupon}
                  disabled={loading}
                  onClick={() => checkCoupon(coupon)}
                >
                  Apply Coupon
                </LoadableButton>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl variant="outlined" className="w-full mt-2">
                  <InputLabel id="select-plan">Plan</InputLabel>
                  <Select
                    labelId="select-plan"
                    id="select-plan-input"
                    value={selectedPlan}
                    onChange={(e) => setPlan(e.target.value as PLANS)}
                    label="Plan"
                    margin="dense"
                    disabled={loading}
                  >
                    {plans.map((plan) => {
                      return (
                        <MenuItem key={plan} value={plan}>
                          {capitalize(plan)} (${planPrices[plan]}
                          /month)
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {selectedPlan === PLANS.ULTIMATE && (
                  <TextField
                    className={classes.qty}
                    variant="outlined"
                    label="Number of eBay Stores"
                    margin="dense"
                    type="number"
                    fullWidth
                    value={ebayIntegrationsQuantity}
                    onChange={(ev) => {
                      const value = parseInt(ev.target.value, 10) || 1;
                      setEbayIntegrationsQuantity(value <= 0 ? 1 : value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="The Ultimate plan comes with one eBay store by default. You can increase this number to integrate additional eBay stores for an extra $5/month/store.">
                            <InfoOutlinedIcon color="action" />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </form>
          <Typography className="mt-2">
            Amount to be charged today: ${displayPrice}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="px-7 py-5">
        <LoadableButton
          onClick={handleLogout}
          color="secondary"
          variant="contained"
          loading={loading}
        >
          Logout
        </LoadableButton>
        <LoadableButton
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          loading={loading}
        >
          Submit
        </LoadableButton>
      </DialogActions>
    </>
  );
}

function EnterPaymentMethodDialog() {
  const requirePaymentMethod = useSelector(paymentMethodSelector);
  const trialEndDate = useSelector(userTrialEndDateSelector);
  const trialEnded = trialEndDate < new Date();
  const [state, setState] = useState<{ open: boolean; trialEnded: boolean }>({
    open: requirePaymentMethod,
    trialEnded: false,
  });
  const open = requirePaymentMethod || trialEnded || state.open;

  useEffect(() => {
    setState((s) => ({ ...s, open: requirePaymentMethod }));
  }, [requirePaymentMethod]);

  useEffect(() => {
    setState((s) => ({ ...s, trialEnded }));
  }, [trialEnded]);

  const handleClose = () => {
    if (!requirePaymentMethod && !trialEnded)
      setState((s) => ({ ...s, open: false }));
  };

  return (
    <>
      <Dialog
        open={open}
        scroll="body"
        fullWidth
        onClose={handleClose}
        maxWidth={state.trialEnded ? "md" : "xs"}
      >
        <CheckFreeTrialEligibilityWrapper>
          {state.trialEnded ? (
            <TrialEndPromptContent
              hasEnded
              onSubscribe={() => {
                setState({
                  open: true,
                  trialEnded: false,
                });
              }}
            />
          ) : (
            <EnterPaymentMethodDialogContent onClose={handleClose} />
          )}
        </CheckFreeTrialEligibilityWrapper>
      </Dialog>
      <TrialEndPrompt
        open={!open}
        onSubscribe={() => setState((s) => ({ ...s, open: true }))}
      />
    </>
  );
}

export default EnterPaymentMethodDialog;
