import React, { useMemo, useState } from "react";
import { XGrid, type XGridProps } from "@material-ui/x-grid";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

function EnhacedXGrid({ rows, componentsProps, ...props }: XGridProps) {
  const [quickFilter, setQuickFilter] = useState<string>();
  const filteredRows = useMemo(() => {
    if (quickFilter) {
      const searchRegex = new RegExp(escapeRegExp(quickFilter), "i");
      return rows.filter((row) => {
        return Object.keys(row).some((field) => {
          if (row[field] instanceof Date) {
            if (
              searchRegex.test(
                row[field].toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                }).replace(',', '')
              ) ||
              searchRegex.test(
                row[field].toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                })
              )
            )
              return true;
            return false;
          }

          return row[field] ? searchRegex.test(row[field].toString()) : false;
        });
      });
    }

    return rows;
  }, [rows, quickFilter]);

  return (
    <XGrid
      {...props}
      rows={filteredRows}
      componentsProps={{
        ...componentsProps,
        toolbar: {
          ...componentsProps?.toolbar,
          quickFilter,
          onQuickFilterChange: (value) => setQuickFilter(value),
        },
      }}
    />
  );
}

export default EnhacedXGrid;
