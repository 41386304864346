import { createSelector } from "reselect";
import { AppState } from "..";
import {
  SupportState,
  SupportStateUserTokenLoading,
  SupportStateUserTokenSuccess,
  SupportStateUserDataLoading,
} from "src/interfaces/supportState.interface";

const getSupportState = (state: AppState) => state.support;

export const supportGetToken = createSelector(
  [getSupportState],
  (support: SupportState) => support.token
);

export const supportIsLoggedIn = createSelector(
  [supportGetToken],
  (token: string) => !!token
);

export const supportGetUserTokens = createSelector(
  [getSupportState],
  (support: SupportState) => support.userTokens
);

export const supportGetValidUserTokens = createSelector(
  [supportGetUserTokens],
  (userTokens: SupportState["userTokens"]) =>
    Object.values(userTokens).filter(
      (t) => (t as SupportStateUserTokenSuccess).value
    ) as SupportStateUserTokenSuccess[]
);

export const supportGenerateTokenIsLoading = createSelector(
  [supportGetUserTokens],
  (userTokens: SupportState["userTokens"]) =>
    Object.values(userTokens).some(
      (token) => (token as SupportStateUserTokenLoading).loading
    )
);

export const supportGetUserData = createSelector(
  [getSupportState],
  (support: SupportState) => support.userData
);

export const supportGetUserIsLoading = createSelector(
  [supportGetUserData],
  (userData: SupportState["userData"]) =>
    Object.values(userData).some(
      (data) => (data as SupportStateUserDataLoading).loading
    )
);

export const supportGetUser = createSelector(
  [supportGetUserData, (_, username: string) => username],
  (userData, username) => userData[username]
);

export const supportEBayLimitsSelector = createSelector(
  [getSupportState],
  (support: SupportState) => support.eBayLimits
);

export const supportEBayLimitsLoadingSelector = createSelector(
  [supportEBayLimitsSelector],
  (eBayLimits: SupportState["eBayLimits"]) =>
    "loading" in eBayLimits ? eBayLimits.loading : false
);

export const supportEBayLimitsLimitsSelector = createSelector(
  [supportEBayLimitsSelector],
  (eBayLimits: SupportState["eBayLimits"]) =>
    "limits" in eBayLimits ? eBayLimits.limits : []
);
