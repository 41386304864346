import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import produce from "immer";
import omit from "lodash/omit";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextField, {
  NumberFieldOnBlur as NumberField,
} from "src/components/TextFieldBlur";

import {
  deleteDownloadsDocuments,
  requestNOUpdate,
  uploadInventoryItems,
} from "src/apiService";
import { updateInventoryItems } from "src/apiService/modules/inventories";
import { getUserIdSync } from "src/config/storage";
import { getNewSyncInventoryDownloads } from "src/store/uploads/selector";
import { formatDate } from "src/utils";
import { StatefulLoadableButton } from "src/components/LoadableButton";
import BrandField from "src/components/BrandField";
import VendorField from "src/components/VendorField";

import type { Inventory } from "src/interfaces/inventory.interface";

const TableCell = withStyles((theme) => ({
  root: { padding: theme.spacing(1) },
}))(MuiTableCell);

export type DataRow = Inventory | RemoveItemFromImport;
export function isDataRowRemoveItemFromImport(
  data: DataRow
): data is RemoveItemFromImport {
  return "remove" in data && data.remove;
}

interface ConfirmDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  downloadTimestamp?: number | undefined;
  data: DataRow[];
}

function ConfirmDialogContent({
  onCancel,
  onConfirm,
  downloadTimestamp,
  data,
}: Omit<ConfirmDialogProps, "open">) {
  const [loading, setLoading] = useState(false);
  const downloads = useSelector(getNewSyncInventoryDownloads);
  return (
    <>
      <DialogTitle>Delete Pending Inventory</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete all these items from pending status?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={loading}
        >
          No
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={loading}
          style={{ position: "relative" }}
          onClick={async () => {
            setLoading(true);
            try {
              const updates = data.reduce((updates, row) => {
                if (isDataRowRemoveItemFromImport(row))
                  updates.push({
                    id: row.inventoryId,
                    listing_id: row.listingId,
                  });
                return updates;
              }, [] as { id: string; listing_id: string }[]);

              if (updates.length) await updateInventoryItems(updates);

              await deleteDownloadsDocuments(
                (downloadTimestamp
                  ? downloads.filter((d) => d.timestamp <= downloadTimestamp)
                  : downloads
                )
                  .filter((d) => d.type.toLowerCase().includes("inventory"))
                  .map((d) => d.id)
              );
              toast.success(
                "All the items were deleted from the pending inventory"
              );
              onConfirm();
            } catch (e) {
              toast.success(
                `Error: ${(e as Error).toString()}. Try again later.`
              );
              setLoading(true);
            }
          }}
        >
          Yes
          {loading && (
            <CircularProgress
              size={24}
              style={{
                marginTop: "-12px",
                marginLeft: "-12px",
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            />
          )}
        </Button>
      </DialogActions>
    </>
  );
}

function ConfirmDialog({
  open,
  onCancel,
  onConfirm,
  downloadTimestamp,
  data,
}: ConfirmDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={(e, r) => {
        if (r) return;
        onCancel();
      }}
      disableEscapeKeyDown
    >
      <ConfirmDialogContent
        onCancel={onCancel}
        onConfirm={onConfirm}
        downloadTimestamp={downloadTimestamp}
        data={data}
      />
    </Dialog>
  );
}

function ConfirmUploadDialog({ open, onCancel, onConfirm, title, text }) {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <StatefulLoadableButton color="primary" onClick={onConfirm}>
          Submit
        </StatefulLoadableButton>
      </DialogActions>
    </Dialog>
  );
}

function Row({ item, onChange, index, items, disabled = false }) {
  const handleDelete = () => onChange(null);
  const findPreviousItem = (index) => {
    for (let i = index - 1; i >= 0; i--) {
      if (items[i]) return items[i];
    }
  };

  return (
    <TableRow>
      <TableCell title={item.item_title}>{item.item_title}</TableCell>
      <TableCell>{item.quantity || 1}</TableCell>
      <TableCell>{item.sku}</TableCell>
      <TableCell>{formatDate(item.list_date)}</TableCell>
      <TableCell width="100">
        <NumberField
          disabled={disabled}
          className="w-full"
          variant="outlined"
          margin="dense"
          value={item.purchase_price}
          required
          onChange={(e) =>
            onChange({ ...item, purchase_price: +(e.target.value || 0) })
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          {...(index === 0 ? { autoFocus: true } : {})}
        />
      </TableCell>
      <TableCell>
        <KeyboardDatePicker
          disabled={disabled}
          disableToolbar
          className="w-full"
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="dense"
          autoOk={true}
          required
          value={item.purchase_date || null}
          onChange={(e: any, t: any) =>
            onChange({ ...item, purchase_date: new Date(t) })
          }
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </TableCell>
      <TableCell>
        <BrandField
          disabled={disabled}
          value={item.brand || ""}
          onChange={(e, value) => onChange({ ...item, brand: value })}
          TextFieldProps={{
            className: "w-full",
            variant: "outlined",
            margin: "dense",
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          disabled={disabled}
          className="w-full"
          variant="outlined"
          margin="dense"
          value={item.location}
          onChange={(e) => onChange({ ...item, location: e.target.value })}
        />
      </TableCell>
      <TableCell>
        <VendorField
          disabled={disabled}
          label={null}
          margin="dense"
          value={item.vendor}
          onChange={(vendor) => onChange({ ...item, vendor: vendor || "" })}
        />
      </TableCell>
      <TableCell>
        <Box display="flex">
          <IconButton
            className="inventory-import-copy-button"
            disabled={
              disabled ||
              index === 0 ||
              !["purchase_price", "purchase_date", "brand", "location"].some(
                (key) => !!findPreviousItem(index)?.[key]
              )
            }
            onClick={() => {
              const prev = findPreviousItem(index);
              const it = { ...item };
              const keys = [
                "purchase_price",
                "purchase_date",
                "brand",
                "location",
                "vendor",
              ];
              for (const k of keys) {
                if (prev[k]) it[k] = prev[k];
                else delete it[k];
              }

              it.purchase_price = it.purchase_price || 0;
              it.brand = it.brand || "";
              it.location = it.location || "";

              onChange(it);
            }}
          >
            <FileCopyOutlinedIcon />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={handleDelete}
            disabled={disabled}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
}

interface RemoveItemFromImport {
  remove: true;
  inventoryId: string;
  listingId: string;
}

function InventorySyncDialogContent({
  data,
  onClose,
  title,
  downloadTimestamp,
}: {
  data: DataRow[];
  onClose: () => void;
  title: React.ReactNode | undefined;
  downloadTimestamp?: number;
}) {
  const downloads = useSelector(getNewSyncInventoryDownloads);
  const [initialData, setInitialData] = useState<DataRow[]>([]);
  const [confirmUpload, setConfirmUpload] = useState(false);
  const [openDeleteAll, setOpenDeleteAll] = useState(false);
  useEffect(() => {
    setInitialData(
      data.map((item) => {
        const nullKeys = Object.keys(item).filter((k) => item[k] === null);
        return nullKeys.length ? omit(item, nullKeys) : item;
      }) as Inventory[]
    );
  }, [data]);

  const handleSubmit = async () => {
    try {
      const user = getUserIdSync();
      const { updates, inventoryItems } = initialData.filter(Boolean).reduce(
        (acc, r) => {
          if (isDataRowRemoveItemFromImport(r))
            acc.updates.push({
              id: r.inventoryId,
              listing_id: r.listingId,
            });
          else
            acc.inventoryItems.push({
              ...r,
              purchase_price: r.purchase_price || 0,
              user,
            });
          return acc;
        },
        {
          updates: [] as { id: string; listing_id: string }[],
          inventoryItems: [] as Inventory[],
        }
      );
      await Promise.all([
        uploadInventoryItems(inventoryItems),
        updateInventoryItems(updates),
      ]);
      await deleteDownloadsDocuments(
        (downloadTimestamp
          ? downloads.filter((d) => d.timestamp <= downloadTimestamp)
          : downloads
        )
          .filter((d) => d.type.toLowerCase().includes("inventory"))
          .map((d) => d.id)
      );
      await requestNOUpdate();
      toast.success("Inventory imported successfully.");
      onClose();
    } catch (e) {
      toast.error(`There was an error: ${(e as Error).toString()}`);
      console.error(e);
    }
  };

  return (
    <>
      <ConfirmDialog
        open={openDeleteAll}
        onCancel={() => {
          setOpenDeleteAll(false);
        }}
        onConfirm={() => onClose()}
        downloadTimestamp={downloadTimestamp}
        data={initialData}
      />
      <ConfirmUploadDialog
        open={confirmUpload}
        onCancel={() => setConfirmUpload(false)}
        onConfirm={handleSubmit}
        title="Complete Import"
        text={`Your import of ${
          initialData.filter((r) => r && !isDataRowRemoveItemFromImport(r))
            .length
        } inventory items is ready to submit.`}
      />
      {title}

      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item Title</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell>List Date</TableCell>
                <TableCell>Purchase Price*</TableCell>
                <TableCell>Purchase Date*</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {initialData.map((item, i) =>
                item && !isDataRowRemoveItemFromImport(item) ? (
                  <Row
                    key={i}
                    index={i}
                    item={item}
                    items={initialData}
                    onChange={(item) => {
                      setInitialData((data) =>
                        produce(data, (draft) => {
                          draft[i] = item;
                        })
                      );
                    }}
                  />
                ) : null
              )}
            </TableBody>
          </Table>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <div id="inventory-import-actions" className="MuiDialogActions-spacing">
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setOpenDeleteAll(true)}
          >
            Remove All
          </Button>
          <Button
            disabled={initialData.some((r) => {
              if (!r) return false;
              if (isDataRowRemoveItemFromImport(r)) return false;
              return !r.purchase_date;
            })}
            color="primary"
            variant="contained"
            onClick={() => setConfirmUpload(true)}
          >
            Add
          </Button>
        </div>
      </DialogActions>
    </>
  );
}

export { InventorySyncDialogContent };
