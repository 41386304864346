import React, { useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Dialog } from "@material-ui/core";

import { toast } from "react-toastify";

import { clearUpdateMethod } from "../../../store/common";
import { platformSelector } from "../../../store/platform/selector";
import { saleStateSelector } from "../../../store/saleState/selector";
import { salesSelector } from "../../../store/sale/selector";
import { inventoriesSelector } from "../../../store/inventory/selector";

import {
  fetchItemsIfNeeded as fetchSaleStates,
  createItem as addSaleState,
} from "src/store/saleState/actions";

import {
  createItem as addSale,
  addBundledSales /*, fetchItems as fetchSales*/,
} from "src/store/sale/actions";

import { setActiveDialog } from "src/store/adminHtml/actions";

import { Sale, SaleFormError } from "src/interfaces";

import { METHOD_TYPE } from "src/enums";
import {
  userPlatformsSelector,
  userGetInventoryTypeSelector,
} from "src/store/system/selector";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import ReportSaleBasicDialog from "./ReportSaleBasicDialog";
import ReportBundleDialog from "./ReportBundleDialog";
import BundleAllocationsDialog from "./BundleAllocationsDialog";
import UploadMatchedSalesReportDialog from "./UploadMatchedSalesReportDialog";

import { fetchItemsIfNeeded as fetchPlatforms } from "src/store/platform/actions";
import { fetchItemsIfNeeded as fetchItemOptions } from "src/store/itemOptions/actions";

import getAllocations, { AllocationValue } from "./getAllocations";

interface IReportSaleDialogProps {
  open: boolean;
}

type SaleNumberKeys =
  | "sale_price"
  | "shipping_cost"
  | "transaction_fees"
  | "sales_tax"
  | "purchase_price";
const initFormValues: Omit<Sale, SaleNumberKeys> &
  Partial<Pick<Sale, SaleNumberKeys>> & { inventory: any } = {
  gross_profit: 0,
  // purchase_price: 0,
  list_date: undefined,
  department: "",
  item_option: "",
  net_profit: 0,
  location: "",
  // transaction_fees: 0,
  // sale_price: 0,
  sub_category: "",
  other_business_costs: 0,
  brand: "",
  estimated_income_tax: 0,
  // sales_tax: 0,
  sale_platform: "",
  sale_tax_paid: "",
  sku: "",
  sale_state: "",
  sale_date: new Date(),
  days_on_platform: 0,
  inventory: null,
  item_title: "",
  id: "",
  platforms_listed: [],
  liable_to_pay: false,
  category: "",
  // shipping_cost: 0,
  purchase_date: new Date(),
  notes: "",
};

const defaultAllocationItem: AllocationValue = {
  item_title: "",
  inventory: null,
  purchase_price: 0,
  sale_price: 0,
  transaction_fees: 0,
  shipping_cost: 0,
};

const ReportSaleDialog = ({ open }: IReportSaleDialogProps) => {
  const inventoryTypeIsCash =
    useSelector(userGetInventoryTypeSelector) === "cash";
  const actionRef = useRef({
    shouldClose: true,
  });
  const dispatch = useDispatch();

  const { items: saleStates } = useSelector(saleStateSelector);
  const { items: inventories } = useSelector(inventoriesSelector);

  const { loading, error, method } = useSelector(salesSelector);

  const userPlatforms = useSelector(userPlatformsSelector);
  const { items: platforms, loading: platformsLoading } =
    useSelector(platformSelector);
  const allPlatforms = [...platforms, ...(userPlatforms || [])];

  const [page, setPage] = React.useState(0);
  const [values, setValues] =
    React.useState<typeof initFormValues>(initFormValues);
  const [allocationValues, setAllocationValues] = React.useState<
    AllocationValue[]
  >([]);
  const [allocationsInitialized, setAllocationsInitialized] =
    React.useState(false);
  const [errors, setErrors] = React.useState<SaleFormError>({});
  const [, setHasChanged] = React.useState<boolean>(false);

  // useEffect(() => {
  //   if (error && !loading && (method == METHOD_TYPE.CREATE)) {
  //     toast.error(error);
  //   }
  // }, [error]);

  const distributeAllocations = () => {
    setAllocationValues(getAllocations(values, allocationValues));
  };

  const goToRoot = () => setPage(0);
  const goToBundle = () => setPage(1);
  const goToAllocations = () => {
    if (!allocationsInitialized) {
      distributeAllocations();
      setAllocationsInitialized(true);
    }

    if (!validateBundle()) return;

    setPage(2);
  };
  const goToUpload = () => setPage(3);

  useEffect(() => {
    if (open) {
      actionRef.current.shouldClose = true;
      setValues(initFormValues);
      goToRoot();
      setAllocationValues([]);
      setAllocationsInitialized(false);
      setErrors({});
      setHasChanged(false);
    }
  }, [open]);

  useEffect(() => {
    if (!open) return;
    dispatch(fetchPlatforms());
    dispatch(fetchItemOptions());
    dispatch(fetchSaleStates());
  }, [open, dispatch]);

  useEffect(() => {
    if (!loading && !error && method === METHOD_TYPE.CREATE) {
      dispatch(clearUpdateMethod());
      if (actionRef.current.shouldClose) {
        handleClose();
      } else {
        actionRef.current.shouldClose = true;
        setValues(initFormValues);
        goToRoot();
        setAllocationValues([]);
        setAllocationsInitialized(false);
        setErrors({});
        setHasChanged(false);
      }
    }
  }, [loading]);

  const handleClose = () => {
    if (!loading) {
      dispatch(setActiveDialog(""));
    }
  };

  const saveBundle = () => {
    if (validateBundle()) {
      let currentSalesTax = 0;
      const salesTax = values.sales_tax || 0;
      const salePrice = values.sale_price || 0;
      const bundleId = "" + new Date().valueOf();
      const newSales = allocationValues
        .map((allocation, index) => {
          const last = index >= allocationValues.length - 1;
          const sales_tax = last
            ? Math.round((salesTax - currentSalesTax) * 100) / 100
            : Math.round((salesTax * allocation.sale_price * 100) / salePrice) /
              100;

          currentSalesTax += sales_tax;

          const sale = {
            ...allocation.inventory,
            quantity: 1,
            purchase_price: allocation.purchase_price,
            shipping_cost: allocation.shipping_cost,
            sale_price: allocation.sale_price,
            sale_platform: values.sale_platform,
            liable_to_pay: values.liable_to_pay,
            sale_state: values.sale_state,
            sale_date: values.sale_date,
            sales_tax: sales_tax,
            transaction_fees: allocation.transaction_fees,
            bundle_id: bundleId,
          };

          if (allocation.inventory) {
            delete sale.import_type;
            delete sale.import_platform;
            delete sale.import_date;

            for (const key of [
              "import_date",
              "import_type",
              "import_platform",
            ]) {
              if (key in allocation.inventory)
                sale[`inventory_${key}`] = allocation.inventory[key];
            }
          }

          return sale;
        })
        .filter((i) => i);
      dispatch(addBundledSales(newSales));
    } else {
      toast.error("Some required information is missing or incomplete.");
    }
  };

  const handleSave = (shouldClose = true) => {
    actionRef.current.shouldClose = shouldClose;
    const stateIndex = saleStates.indexOf(values.sale_state);
    if (stateIndex === -1 && values.sale_state) {
      dispatch(addSaleState(values.sale_state));
    }
    if (page > 0) {
      return saveBundle();
    }
    if (validateForm()) {
      const inventory = values.inventory;
      const payload = {
        ...inventory,
        platforms_listed: inventory.platforms_listed || [],
        shipping_cost: values.shipping_cost || 0,
        sale_price: values.sale_price || 0,
        purchase_price: values.purchase_price || 0,
        sale_platform: values.sale_platform,
        liable_to_pay: values.liable_to_pay,
        list_date: values.list_date || null,
        sale_state: values.sale_state,
        sale_date: values.sale_date,
        sales_tax: values.sales_tax || 0,
        transaction_fees: values.transaction_fees || 0,
        shipping_cost_analytics: values.shipping_cost_analytics || 0,
        other_fees: values.other_fees || 0,
        purchase_date: values.purchase_date || values.inventory?.purchase_date,
        department: values.department || values.inventory?.department,
        category: values.category || values.inventory?.category,
        sub_category: values.sub_category || values.inventory?.sub_category,
        return_id: null,
      };

      if (inventory) {
        delete payload.import_type;
        delete payload.import_platform;
        delete payload.import_date;

        for (const key of ["import_date", "import_type", "import_platform"]) {
          if (key in inventory) payload[`inventory_${key}`] = inventory[key];
        }
      }

      if (inventoryTypeIsCash) {
        payload.purchase_price = 0;
        payload.purchase_date = payload.sale_date;
      }

      dispatch(
        addSale(
          Object.entries(payload).reduce((p, [k, v]) => {
            if (v !== undefined) p[k] = v;
            return p;
          }, {})
        )
      );
    } else {
      toast.error("Some required information is missing or incomplete");
    }
  };

  const handleChange =
    (prop: keyof Sale) => (event: React.ChangeEvent<any>, t?: any) => {
      setHasChanged(true);
      let value = event.target.value;
      if ("sale_platform" === prop) {
        value = value > -1 ? allPlatforms[value] : "";
      } else if ("liable_to_pay" === prop) {
        value = !!value;
      }
      setValues({ ...values, [prop]: value });
    };

  const isValidDate = (d: any) => {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const validateForm = () => {
    let hasError = false;
    /* rules */
    let newErrors: SaleFormError = { ...errors };
    // const inventory = inventories.find(e => e.item_title === values.item_title);
    newErrors.purchase_price = undefined;
    if (!values.inventory) {
      hasError = true;
      newErrors.item_title = "Must select an existing inventory item.";
      // } else if (values.item_title === undefined ||
      //   values.item_title.trim() === "") {
      //   hasError = true;
      //   newErrors.item_title = "Required field!";
    } else {
      newErrors.item_title = undefined;
      const quantity = values.inventory.quantity || 1;
      if (quantity > 1) {
        const purchasePrice = (values as any).purchase_price_total || 0;
        if (purchasePrice && (values.purchase_price || 0) > purchasePrice) {
          hasError = true;
          newErrors.purchase_price = `Purchase price ($${values.purchase_price}) must be lower or equal than the inventory item's purchase price ($${purchasePrice})`;
        }
      }
    }

    // XXX: default sale_price to 0
    // if (values.sale_price === undefined) {
    //   newErrors.sale_price = "Required field!";
    //   hasError = true;
    // } else {
    newErrors.sale_price = undefined;
    // }

    if (
      !values.sale_platform ||
      values.sale_platform === undefined ||
      values.sale_platform.trim() === ""
    ) {
      newErrors.sale_platform = "Required field!";
      hasError = true;
    } else {
      newErrors.sale_platform = undefined;
    }

    if (!isValidDate(values.sale_date)) {
      hasError = true;
      newErrors.sale_date = "Invalid date format";
    } else {
      newErrors.sale_date = undefined;
    }

    setErrors(newErrors);

    return !hasError;
  };

  const validateBundle = () => {
    let hasError = false;
    /* rules */
    let newErrors: SaleFormError = { ...errors };
    if (values.sale_price === undefined) {
      newErrors.sale_price = "Required field!";
      hasError = true;
    } else {
      newErrors.sale_price = undefined;
    }

    if (
      values.sale_platform === undefined ||
      values.sale_platform.trim() === ""
    ) {
      newErrors.sale_platform = "Required field!";
      hasError = true;
    } else {
      newErrors.sale_platform = undefined;
    }

    if (!isValidDate(values.sale_date)) {
      hasError = true;
      newErrors.sale_date = "Invalid date format";
    } else {
      newErrors.sale_date = undefined;
    }

    setErrors(newErrors);

    return !hasError;
  };

  const renderOption = (option, { inputValue }) => {
    const matches = match(option, inputValue);
    const parts = parse(option, matches);

    return (
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    );
  };

  const addItemToBundle = () => {
    setAllocationsInitialized(false);
    setAllocationValues((current) => [
      ...current,
      { ...defaultAllocationItem },
    ]);
  };

  const removeItemFromBundle = (index) => {
    setAllocationValues((current) =>
      current.filter((currentItem, currentIndex) => currentIndex !== index)
    );
  };

  const renderContent = () => {
    switch (page) {
      case 1:
        return (
          <ReportBundleDialog
            errors={errors}
            handleChange={handleChange}
            values={values}
            setValues={setValues}
            loading={loading}
            inventories={inventories}
            saleStates={saleStates}
            renderOption={renderOption}
            allocationValues={allocationValues}
            setAllocationValues={setAllocationValues}
            allocationsInitialized={allocationsInitialized}
            setAllocationsInitialized={setAllocationsInitialized}
            addItemToBundle={addItemToBundle}
            removeItemFromBundle={removeItemFromBundle}
            setHasChanged={setHasChanged}
            goToRoot={goToRoot}
            goToAllocations={goToAllocations}
          />
        );
      case 2:
        return (
          <BundleAllocationsDialog
            values={values}
            allocationValues={allocationValues}
            setAllocationValues={setAllocationValues}
            bundleValues={values}
            loading={loading}
            goToBundle={goToBundle}
            handleSave={() => handleSave()}
          />
        );
      case 3:
        return <UploadMatchedSalesReportDialog onBack={() => goToRoot()} />;
      case 0:
      default:
        return (
          <ReportSaleBasicDialog
            inventories={inventories}
            values={values}
            setValues={setValues}
            errors={errors}
            renderOption={renderOption}
            loading={loading}
            handleChange={handleChange}
            saleStates={saleStates}
            platformsLoading={platformsLoading}
            allPlatforms={allPlatforms}
            handleSave={handleSave}
            goToBundle={goToBundle}
            goToUpload={goToUpload}
          />
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="body"
      fullWidth
      maxWidth={page < 3 ? "md" : "sm"}
      disableEnforceFocus
    >
      {renderContent()}
    </Dialog>
  );
};

export default ReportSaleDialog;
