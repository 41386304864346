import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import Plot from "react-plotly.js";

import { userTrialEndDateSelector } from "src/store/system/selector";
import { getTrialEstimationsSelector } from "src/store/trial/selector";
import SvgGradientDef, {
  createDefs,
  makeBarFill,
  GradientsByName,
  Orientation,
} from "src/components/SvgGradientDef";

const MinuteThreshold = 3 * 60 * 60; // 3 hours (seconds)

const TrialEndCounterKey = "__trial_end_counter_key__";
const DayMs = 24 * 60 * 60 * 1000;

const svgDefs = createDefs(
  Orientation.horizontal,
  [GradientsByName.LightBlue],
  "horizontal"
);

const Milliseconds = 2000;
const Frame = 16;

export interface TrialEndPromptContentProps {
  hasEnded?: boolean;
  onSubscribe: () => void;
}

function scaleEstimation(estimation: number, divider: number) {
  return Math.round((estimation / divider) * 100) / 100;
}

function estimationsToScale(trialEstimations): {
  divider: number;
  unit: "Minutes" | "Hours";
} & ReturnType<typeof getTrialEstimationsSelector> {
  const divider = trialEstimations.totalTime > MinuteThreshold ? 3600 : 60;
  return Object.entries(trialEstimations).reduce(
    (estimations, [k, v]) => {
      estimations[k] = scaleEstimation(v as number, divider);
      return estimations;
    },
    {
      ...trialEstimations,
      divider,
      unit: divider === 60 ? "Minutes" : "Hours",
    }
  );
}

const useStyles = makeStyles((theme) => ({
  plotWrapper: {
    padding: theme.spacing(2, 4),
    margin: theme.spacing(1),
  },
  plot: {
    ...makeBarFill([
      {
        ...GradientsByName.LightBlue,
        name: `${GradientsByName.LightBlue.name}-horizontal`,
      },
    ]),
    "& g.cartesianlayer g.plot g.barlayer.mlayer g.trace.bars g.points g.point path":
      {
        clipPath: "inset(0% 0% 0% 0% round 0px 8px 8px 0px)",
      },
  },
  savings: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  actions: {
    justifyContent: "center",
  },
}));

export function TrialEndPromptContent({
  hasEnded,
  onSubscribe,
}: TrialEndPromptContentProps) {
  const classes = useStyles();
  const trialEstimations = useSelector(getTrialEstimationsSelector);
  const trialEndDate = useSelector(userTrialEndDateSelector);

  const scaledEstimations = estimationsToScale(trialEstimations);
  const [anualEstTimeSavings, setAnualEstTimeSavings] = useState(0);
  const scaledEstimationsAnualEstTimeSavings = scaleEstimation(
    trialEstimations.anualEstTimeSavings,
    3600
  );

  useEffect(() => {
    setAnualEstTimeSavings(0);
    let cancel = false;
    const steps = Math.round(Milliseconds / Frame);
    const step = scaledEstimationsAnualEstTimeSavings / steps;
    let timeout: ReturnType<typeof setTimeout> | undefined;
    const f = () => {
      if (cancel) return;
      setAnualEstTimeSavings((a) => {
        const n = a + step;
        return n > scaledEstimationsAnualEstTimeSavings
          ? scaledEstimationsAnualEstTimeSavings
          : n;
      });

      timeout = setTimeout(f, Frame);
    };

    f();
    return () => {
      cancel = true;
      clearTimeout(timeout);
    };
  }, [scaledEstimationsAnualEstTimeSavings]);

  return (
    <>
      <SvgGradientDef defs={svgDefs} />
      <DialogTitle>
        {hasEnded ? "Your trial has ended." : "Your trial is almost over."}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {hasEnded
            ? "Looks like you’ve saved a lot of time on bookkeeping while using My Reseller Genie!"
            : "Looks like you’ve saved a lot of time on bookkeeping since you started using My Reseller Genie!"}
        </DialogContentText>
        <Paper className={classes.plotWrapper}>
          <Plot
            className={classes.plot}
            data={[
              {
                type: "bar",
                marker: { color: GradientsByName.LightBlue.color },
                orientation: "h",
                y: ["Manual Entry", "My Reseller Genie"],
                x: [scaledEstimations.totalTime, scaledEstimations.mrgTime],
              },
            ]}
            config={{
              displaylogo: false,
              responsive: true,
              displayModeBar: false,
            }}
            layout={{
              autosize: true,
              height: 300,
              hovermode: false,
              clickmode: "none",
              dragmode: false,
              margin: {
                t: 16,
                r: 16,
                l: 130,
                pad: 10,
              },
              xaxis: {
                title: scaledEstimations.unit,
              },
            }}
          />
        </Paper>
        <Paper className={classes.savings}>
          <Typography variant="caption">Annual Est. Time Savings</Typography>
          <Typography variant="h1" component="p" align="center">
            {Math.floor(anualEstTimeSavings)}
          </Typography>
          <Typography variant="h6" component="p" align="center">
            Hours
          </Typography>
        </Paper>
        {hasEnded ? (
          <>
            <Typography variant="body1" align="center">
              That leaves a lot more time for what you want to do!
            </Typography>
            <Typography variant="body1" align="center">
              Want to keep saving time? <b>Subscribe today to regain access.</b>
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body1" align="center">
              That leaves a lot more time for what you want to do!
            </Typography>
            <Typography variant="body1" align="center">
              Want to keep saving time?{" "}
              <b>
                Subscribe before{" "}
                {(trialEndDate || new Date()).toLocaleString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}{" "}
                to avoid interruption of service.
              </b>
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSubscribe()}
        >
          Subscribe Now
        </Button>
      </DialogActions>
    </>
  );
}

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.grey[50],
  },
}));

function TrialEndPrompt({
  open: shouldOpen,
  onSubscribe,
}: {
  open: boolean;
  onSubscribe: TrialEndPromptContentProps["onSubscribe"];
}) {
  const classes = useDialogStyles();
  const trialEndDate = useSelector(userTrialEndDateSelector);
  const [open, setOpen] = useState(shouldOpen);
  const [shouldOpenByEndDate, setShouldOpenByEndDate] = useState(false);
  const openRef = useRef({
    open,
    shouldOpenByEndDate,
  });
  openRef.current.open = open;
  openRef.current.shouldOpenByEndDate = shouldOpenByEndDate;

  useEffect(() => {
    if (!trialEndDate) {
      setShouldOpenByEndDate(false);
      return;
    }

    if (!openRef.current.open) return;
    if (openRef.current.shouldOpenByEndDate) return;

    const now = new Date();
    const today = [now.getFullYear(), now.getMonth() + 1, now.getDate()]
      .map((d) => `${d}`.padStart(2, "0"))
      .join("-");
    let counter = 0;
    try {
      const data = JSON.parse(localStorage.getItem(TrialEndCounterKey) || "");
      if (data.date === today) {
        counter = data.counter || 0;
      }
    } catch (e) {}
    const timeDiff = trialEndDate.valueOf() - now.valueOf();

    if (timeDiff < 0) {
      return;
    } else if (timeDiff < 1 * DayMs) {
      if (counter >= 3) return;
    } else if (timeDiff < 2 * DayMs) {
      if (counter >= 2) return;
    } else if (timeDiff < 3 * DayMs) {
      if (counter >= 1) return;
    } else {
      return;
    }

    setShouldOpenByEndDate(true);
    counter++;
    localStorage.setItem(
      TrialEndCounterKey,
      JSON.stringify({
        counter,
        date: today,
      })
    );
  }, [trialEndDate]);

  return (
    <Dialog
      open={open && shouldOpenByEndDate}
      scroll="body"
      fullWidth
      maxWidth="md"
      classes={classes}
      onClose={() => {
        setOpen(false);
      }}
    >
      <TrialEndPromptContent onSubscribe={onSubscribe} />
    </Dialog>
  );
}

export default TrialEndPrompt;
