import { useState, useEffect } from "react";
import { getUserId } from "../../config";
import firebase from "firebase/app";
import "firebase/storage";
import firestore from "src/apiService/firestore";

const db = firestore();

export type PlaidIntegrationType = "expense" | "cash";
export interface PlaidLinkData {
  type: PlaidIntegrationType;
  name: string;
  date: Date;
  redirect?: string;
}
export interface PlaidLinkBag {
  linkToken?: string;
  data?: PlaidLinkData;
  error?: string;
}

export async function fetchPlaidLinkToken() {
  const f = firebase.functions().httpsCallable("getPlaidLinkToken");
  const result = await f();
  return result.data;
}

export const PlaidLinkKey = "__MRG_PlaidLinkKey__";
function getStoredLinkData(): PlaidLinkBag {
  try {
    const stored = window.localStorage.getItem(PlaidLinkKey);
    const json = JSON.parse(stored || "{}");
    if (json.data?.date) {
      json.data.date = new Date(json.data.date);
      json.data.date.setHours(0, 0, 0, 0);
    }
    return json;
  } catch (e) {
    return { error: (e as Error).toString() };
  }
}

function updateStoredLinkData(bag: PlaidLinkBag) {
  let mergedBag: PlaidLinkBag = {};
  const stored = getStoredLinkData();
  if (!stored.error) mergedBag = stored;
  window.localStorage.setItem(
    PlaidLinkKey,
    JSON.stringify({
      ...mergedBag,
      ...bag,
    })
  );
}

export function usePlaid(data?: Partial<PlaidLinkData>): PlaidLinkBag {
  const [link, setLink] = useState<PlaidLinkBag>({});

  useEffect(() => {
    if (window.location.pathname.endsWith("/auth/plaid/callback")) {
      setLink(getStoredLinkData());
      return;
    }

    let cancel = false;
    (async () => {
      const linkToken = await fetchPlaidLinkToken();
      if (cancel) return;
      setLink((l) => ({ ...l, linkToken }));
      updateStoredLinkData({ linkToken });
    })();

    return () => {
      cancel = true;
    };
  }, []);

  // XXX: this isn't the best solution, but, well, it works.
  //      should return a setter instead.
  useEffect(() => {
    if (data?.type && data?.name && data?.date) {
      updateStoredLinkData({
        data: {
          type: data.type,
          name: data.name,
          date: data.date,
          redirect: data.redirect,
        },
      });
    }
  }, [data?.type, data?.name, data?.date, data?.redirect]);

  return link;
}

export const fetchPlaidUpdateModeLinkToken = () => {
  const getPlaidUpdateModeLinkFunction = firebase
    .functions()
    .httpsCallable("getPlaidUpdateModeLinkToken", { timeout: 10000 });
  return getUserId().then((user_id) => {
    return getPlaidUpdateModeLinkFunction({ user_id: user_id })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export async function transferPlaidPublicToken(
  public_token: string,
  type: PlaidIntegrationType | null,
  extra: { integration_id?: string } | { name: string; date?: Date }
) {
  const f = firebase.functions().httpsCallable("exchangePlaidPublicToken");
  try {
    const data = await f({
      ...extra,
      public_token: public_token,
      type,
    });
    return data.data;
  } catch (err) {
    console.log(err);
    return "";
  }
}

export const deleteIntegration = async (integrationId) => {
  const deleteIntegration = firebase
    .functions()
    .httpsCallable("deletePlaidIntegration");
  try {
    const data = await deleteIntegration({ integration_id: integrationId });
    return data.data;
  } catch (err) {
    console.log(err);
    return "";
  }
};

export const deleteTransactions = () => {
  return getUserId().then(async (userId) => {
    const ref = db.collection("Transactions").where("user", "==", userId);
    ref.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        doc.ref.delete();
      });
    });
  });
};

export const deleteCashActivities = () => {
  return getUserId().then(async (userId) => {
    const ref = db.collection("Cash_Activities").where("user", "==", userId);
    ref.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        doc.ref.delete();
      });
    });
  });
};

export async function syncAccount(doc_id) {
  const f = firebase.functions().httpsCallable("getPlaidTransactions");
  return (await f({ doc_id: doc_id })).data;
}
