import React, { useRef, useEffect } from "react";
import debouce from "lodash/debounce";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";

function GridToolbarQuickFilterField({
  quickFilter,
  onQuickFilterChange,
  placeholder = "Search…",
}) {
  const inputRef = useRef<HTMLInputElement>();
  const onQuickFilterChangeRef = useRef(onQuickFilterChange);
  onQuickFilterChangeRef.current = onQuickFilterChange;

  const debouncedOnChange = useRef(
    debouce((v) => {
      onQuickFilterChangeRef.current(v);
    }, 300)
  );

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = quickFilter || "";
  }, [quickFilter]);

  return (
    <TextField
      variant="standard"
      size="small"
      onChange={(ev) => debouncedOnChange.current(ev.target.value)}
      placeholder={placeholder}
      inputRef={inputRef}
      InputProps={{
        startAdornment: <SearchIcon fontSize="small" />,
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{ visibility: quickFilter ? "visible" : "hidden" }}
            onClick={() => onQuickFilterChange("")}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
    />
  );
}

export default GridToolbarQuickFilterField;
