import React from "react";
import Tour from "reactour";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import DialogTitleWithClose from "src/components/DialogTitleWithClose";
import { setActiveDialog } from "src/store/adminHtml/actions";
import { paymentMethodSelector } from "src/store/system/selector";

const tourConfig = [
  {
    content: () => (
      <>
        <DialogTitle>Welcome to My Reseller Genie (1/3)</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Thanks for signing up - we want to show you a couple things to help
            you get started.
          </DialogContentText>
        </DialogContent>
      </>
    ),
  },
  {
    selector: "#get-started",
    content: () => (
      <>
        <DialogTitle>Welcome to My Reseller Genie (2/3)</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Click here to get started setting up your Genie!
          </DialogContentText>
        </DialogContent>
      </>
    ),
  },
  {
    selector: "#launcher, style + iframe[title][style]",
    content: (props) => (
      <>
        <DialogTitleWithClose onClose={() => props.close()}>
          Welcome to My Reseller Genie (3/3)
        </DialogTitleWithClose>
        <DialogContent>
          <DialogContentText>
            Need quick help? Search for articles in this chat or leave us a
            message.
          </DialogContentText>
        </DialogContent>
      </>
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  helper: {
    maxWidth: "80%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
    },
    padding: theme.spacing(0, 0, 2),
  },
  mask: {
    color: "#021d1d",
    opacity: "0.5",
  },
}));

const accentColor = "rgb(4, 76, 38)";

const EmptyTablesDialog = ({ open }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const requirePaymentMethod = useSelector(paymentMethodSelector);

  const handleClose = () => {
    dispatch(setActiveDialog(""));
  };

  return (
    <Tour
      startAt={0}
      closeWithMask={false}
      disableDotsNavigation
      steps={tourConfig}
      isOpen={open && !requirePaymentMethod}
      rounded={5}
      accentColor={accentColor}
      onRequestClose={handleClose}
      className={classes.helper}
      maskClassName={classes.mask}
      showCloseButton={false}
      CustomHelper={null}
      disableFocusLock
    />
  );
};

export default EmptyTablesDialog;
