import React, { useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { usePlaidLink } from "react-plaid-link";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  usePlaid,
  transferPlaidPublicToken,
} from "src/apiService/modules/plaid";

function PlaidAuthCallbackPage() {
  const history = useHistory();
  const { data, linkToken } = usePlaid();
  const { open, ready } = usePlaidLink({
    receivedRedirectUri: window.location.href,
    token: linkToken || null,
    onSuccess: async (publicToken) => {
      if (data) {
        await transferPlaidPublicToken(publicToken, data.type, {
          name: data.name,
          date: data.date,
        });
        toast.success("Connected successfully");
      }

      history.push(
        data?.redirect
          ? data.redirect.replace("{name}", encodeURIComponent(data.name))
          : "/management"
      );
    },
    onExit: (err) => {
      if (err) {
        console.error(err);
        toast.error("There was an error. Try again");
        history.push(data?.redirect ? data.redirect : "/management");
      }
    },
  });
  const openRef = useRef(open);
  openRef.current = open;

  useEffect(() => {
    if (ready) openRef.current();
  }, [ready]);

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  );
}

export default PlaidAuthCallbackPage;
